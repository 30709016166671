.flex {
    display: flex;
}
.flex-0 {
    flex: 0;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}
.flex-5 {
    flex: 5;
}
.flex-6 {
    flex: 6;
}
.flex-7 {
    flex: 7;
}
.flex-8 {
    flex: 8;
}
.flex-9 {
    flex: 9;
}
.flex-08 {
    flex: 0.8;
}
.flex-09 {
    flex: 0.9;
}

.flex-direction-row {
    flex-direction: row;
}
.flex-direction-column {
    flex-direction: column;
}
.flex-direction-row-reverse {
    flex-direction: row-reverse;
}
.flex-direction-column-reverse {
    flex-direction: column-reverse;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}
.flex-wrap-nowrap {
    flex-wrap: nowrap;
}
.flex-wrap-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-around {
    justify-content: space-around;
}
.justify-content-evenly {
    justify-content: space-evenly;
}

.align-items-start {
    align-items: flex-start;
}
.align-items-end {
    align-items: flex-end;
}
.align-items-center {
    align-items: center;
}
.align-items-baseline {
    align-items: baseline;
}
.align-items-stretch {
    align-items: stretch;
}

.align-content-start {
    align-content: flex-start;
}
.align-content-end {
    align-content: flex-end;
}
.align-content-center {
    align-content: center;
}
.align-content-between {
    align-content: space-between;
}
.align-content-around {
    align-content: space-around;
}
.align-content-stretch {
    align-content: stretch;
}

.align-self-auto {
    align-self: auto;
}
.align-self-start {
    align-self: flex-start;
}
.align-self-end {
    align-self: flex-end;
}
.align-self-center {
    align-self: center;
}
.align-self-baseline {
    align-self: baseline;
}
.align-self-stretch {
    align-self: stretch;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
    font-size: unset;
}
.border-none {
    border: none;
}
.border-left {
    border-left: none !important;
}
.border-right {
    border-right: none !important;
}

.overflow-hidden {
    overflow: hidden;
}
.overflow-auto {
    overflow: auto;
}
.h-100 {
    height: 100%;
}
.w-100 {
    width: 100%;
}

.fs-11 {
    font-size: 0.75rem;
}
.fs-12 {
    font-size: 0.775rem;
}
.fs-13 {
    font-size: 0.8125rem;
}
.fs-14 {
    font-size: 0.875rem;
}
.fs-big {
    font-size: 1.5rem; // 24px
}
.fs-medium {
    font-size: 1.125rem; // 18px
}
.fs-normal {
    font-size: 0.75rem; // 12px
}
.fs-small {
    font-size: 0.6875rem; // 11px
}
.fs-verySmall {
    font-size: 0.625rem; // 10px
}
.fs-smallest {
    font-size: 0.5rem; // 8px
}
.fs-tab {
    font-size: 1rem; //16px
}

.text-title-1 {
    color: VAR(--TEXT__TITLE__1); // Công (màu title)
}
.text-primary {
    color: var(--PRIMARY__CONTENT__COLOR);
}
.text-second {
    color: var(--SECOND__CONTENT__COLOR);
}
.text-third {
    color: var(--THIRD__CONTENT__COLOR);
}

.text-placeholder {
    color: var(--PLACEHODLER__COLOR);
}
.text-header {
    color: var(--HEADER__CONTENT__COLOR);
}
.text-white {
    color: #fff;
}
.text-brand {
    color: var(--PRIMARY);
}
.text-error {
    color: var(--ERROR__COLOR);
}
.text-warn {
    color: var(--WARN__COLOR);
}
.text-success {
    color: var(--SUCCESS__COLOR);
}
.text-info {
    color: var(--INFO__COLOR);
}
.price-ref {
    color: var(--REF__COLOR);
    text-shadow: currentColor 0.1px 0.1px 0.1px;
}
.price-up {
    color: var(--UP__COLOR);
    text-shadow: currentColor 0.1px 0.1px 0.1px;
}
.price-down {
    color: var(--DOWN__COLOR);
    text-shadow: currentColor 0.1px 0.1px 0.1px;
}
.price-ceil {
    color: var(--CEIL__COLOR);
    text-shadow: currentColor 0.1px 0.1px 0.1px;
}
.price-floor {
    color: var(--FLOOR__COLOR);
    text-shadow: currentColor 0.1px 0.1px 0.1px;
}

.bg-primary {
    background-color: var(--PRIMARY__BG__COLOR);
}
.bg-second {
    background-color: var(--SECOND__BG__COLOR);
}
.bg-third {
    background-color: var(--THIRD__BG__COLOR);
}
.bg-fourth {
    background-color: var(--FOURTH__BG__COLOR);
}
.bg-input {
    background-color: var(--INPUT__BG__LOGIN);
}
.bg-modal {
    background-color: var(--HEADER__BG__COLOR);
}
.bg-brand {
    background-color: var(--PRIMARY);
}
.bg-up {
    background-color: var(--UP__COLOR);
}
.bg-down {
    background-color: var(--DOWN__COLOR);
}

.fw-100 {
    font-weight: 100;
}
.fw-200 {
    font-weight: 200;
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.fw-900 {
    font-weight: 900;
}
// Công
.fw-bold {
    font-weight: bold;
}

.no-height {
    height: unset;
}

.center__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &--right {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: flex-end;
    }
    &--left {
        display: flex;
        align-items: flex-start;
        text-align: center;
        justify-content: flex-start;
    }
    &--center {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}

.cursor-default {
    cursor: default !important;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-text {
    cursor: text;
}
.cursor-resize {
    cursor: ns-resize;
    cursor: url(data:image/gif;base64,R0lGODlhCQALAPAAAP///w9wuCH5BAEAAAAALAAAAAAJAAsAAAIUhBGnwYrcDJxvQrfs1UnS3DkhUAAAOw==),auto;
}

.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}

// Alignment

.text-justify {
    text-align: justify !important;
}
.text-nowrap {
    white-space: nowrap !important;
}

// Transformation

.text-lowercase {
    text-transform: lowercase !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}

// Weight and italics
.font-italic {
    font-style: italic !important;
}
.font-underline {
    border-bottom: 1px dashed var(--TEXT__2);
}

.padding-right-table {
    padding-right: 4px !important;
}
.padding-left-table {
    padding-left: 4px !important;
}
.border-table {
    border: 1px solid var(--BORDER__COLOR);
}

.text-ellip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-auto {
    overflow: auto;
}

.radius-1 {
    border-radius: 4px;
}
.radius-2 {
    border-radius: 8px;
}

.refesh {
    transform: rotate(360deg);
    transition: 5s;
}

.display-content {
    display: contents;
}

.price-history-detail .ant-table-expanded-row-fixed{
  min-height: 300px;
}
.price-history-detail .empty-size{
    left: 40%
}
.price-history-normal .ant-table-expanded-row-fixed{
    min-height: 190px;
}
.price-history-normal .empty-size{
    left: 36%
}
.price-history-detail .ant-empty-normal{
    margin: 92px 0;
}

#checkBoxTab .ant-tabs-nav-wrap{
    flex: none !important;
}