.stocklive__news {
	max-height: 100%;
	width: 100%;
	overflow-x: auto;

	.news-iconEn-date {
		border: 1px solid var(--BORDER__MODAL);
		border-radius: 10px;
		display: flex;
		justify-content: space-between;
		width: 150px;
		height: 100px;
		background-color: var(--INPUT__BG);
		padding: 0.5rem 0.5rem 0.5rem;

		.news-iconEn-month {
			font-size: 25px;
		}

		.news-iconEn-day {
			font-size: 25px;
			justify-content: flex-end;
			display: flex;
			flex-direction: column;
			text-align: center;
		}
	}

	.news-iconVi-date {
		border: 1px solid var(--BORDER__MODAL);
		border-radius: 10px;
		display: flex;
		padding-inline: 0.5rem;
		justify-content: space-between;
		width: 150px;
		height: 100px;
		background-color: var(--INPUT__BG);

		.news-iconVi-month {
			font-size: 20px;
			display: flex;
			flex-direction: column;
			text-align: center;
		}

		.news-iconVi-day {
			font-size: 20px;
			justify-content: flex-end;
			display: flex;
			flex-direction: column;
			text-align: center;
		}
	}
}
