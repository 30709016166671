.order-list-container {
  .ant-checkbox + span {
    padding-right: 4px;
    padding-left: 4px;
  }
}

.order-list table thead tr th, .sell-list table thead tr th {
  position: sticky;
  top: -1px;
  background-color: var(--TABLE__HEADER__BG);
  z-index: 4;
}
.ant-table-filter-dropdown-btns {
  display: none;
}

.sell-list table tbody tr {
  .price-up {
    color: var(--UP__COLOR);
    text-shadow: currentColor 0.1px 0.1px 0.1px;
  }
  .price-down {
      color: var(--DOWN__COLOR);
      text-shadow: currentColor 0.1px 0.1px 0.1px;
  }
  .price-ceil {
      color: var(--CEIL__COLOR);
      text-shadow: currentColor 0.1px 0.1px 0.1px;
  }
  .price-floor {
      color: var(--FLOOR__COLOR);
      text-shadow: currentColor 0.1px 0.1px 0.1px;
  }
}