.cash__transfer {
    .card-cash-transfer {
        border-radius: 8px;
        border: 1px solid VAR(--INPUT__BORDER);
        background: VAR(--BG__2);
    }
    .card-cash-transfer-left {
        height: 100%;
        // min-height: 520px;
        .ant-card-body {
            padding: 0.5rem;
        }
    }
    .card-cash-transfer-right {
        height: 100%;
        .ant-card-body {
            padding: 0 0 0.5rem 0;
        }
        .table-card-cash-right {
            .table-card-cash-right-content{
                overflow: auto;
                // max-height: calc(100% - 205px);
                max-height: calc(100vh - 250px);
                border: 1px solid var(--INPUT__BORDER);
                th {
                    height: 35px;
                    position: sticky;
                    z-index: 4;
                    background-color: var(--TABLE__HEADER__BG);
                    top: 0px;
                    border-left: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
                td {
                    height: 30px;
                    border: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
            }
        }
    }
}




// .view {
//     margin: auto;
//     width: 600px;
//   }
  
//   .wrapper {
//     position: relative;
//     overflow: auto;
//     border: 1px solid black;
//     white-space: nowrap;
//   }
  
//   .sticky-col {
//     position: -webkit-sticky;
//     position: sticky;
//     background-color: white;
//   }
  
//   .first-col {
//     width: 100px;
//     min-width: 100px;
//     max-width: 100px;
//     left: 0px;
//   }
  
//   .second-col {
//     width: 150px;
//     min-width: 150px;
//     max-width: 150px;
//     left: 100px;
//   }
