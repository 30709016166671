::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--BG__1);
  border-radius: 6px;
}
::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--BG__2);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--TEXT__2);
  border-radius: 6px;
}
// ::-webkit-scrollbar-button {
//     background-color: unset;
//     width: 0px !important;
//     height: 0px !important;
// }

::-webkit-scrollbar-track-piece {
    background-color: var(--BG__2);
}

::-webkit-scrollbar-corner {
  background: var(--BG__2);
}


.scrollbox {
    overflow: auto;
    visibility: hidden;
}
.scrollbox-content,
.scrollbox:hover,
.scrollbox:focus {
    visibility: visible;
}
.scrollbox_delayed {
    transition: visibility 0.2s;
}
.scrollbox_delayed:hover {
    transition: visibility 0s 0.2s;
}
