/**
* Định nghĩa các class name hay dùng
*/

.alt-text {
    color: var(--PRIMARY__CONTENT__COLOR)
}
.alt-fs-smallest {
    font-size: var(--smallest);
}
.alt-fs-big {
    font-size: var(--big);
}
.alt-fs-medium {
    font-size: var(--medium);
}
.alt-fs-xxnormal {
    font-size: var(--xxnormal);
}
.alt-fs-xnormal {
    font-size: var(--xnormal);
}
.alt-fs-normal {
    font-size: var(--normal);
}
.alt-fs-small {
    font-size: var(--small);
}
.alt-fs-verySmall {
    font-size: var(--verySmall);
}