.modal-bond-detail {
    .row-label {
        width: 270px;
    }

    .row-value {
        width: 100%;
    }
}


.date-order-bonds.ant-picker-small {
    padding: 2px 7px;

    input {
        font-size: 0.75rem;
    }
}

.modal-contract-bond {
    .ant-modal-header {
        background-color: var(--HEADER__BG__COLOR);

        .ant-modal-title {
            text-align: center;
        }
    }

    tr:nth-child(even):hover,
    tr:nth-child(odd):hover {
        background-color: var(--HEADER__BG__COLOR) !important;
    }

    .ant-modal-body {
        max-height: 60vh;
    }

    .ant-modal-header {
        border-bottom-color: var(--HEADER__BG__COLOR);
    }

    .ant-modal-footer {
        border-top-color: var(--HEADER__BG__COLOR);
    }
}

.bond-advance-table {
    overflow: auto;
    height: 100%;

    table {
        border-spacing: 0;
        border-collapse: separate;
        font-size: 0.75rem;
        width: 100%;

        thead,
        tfoot {
            z-index: 4;

            tr {

                th {
                    position: sticky;
                    top: 0px;
                    background-color: var(--TABLE__HEADER__BG);
                    padding: 0px 4px;
                    white-space: nowrap;
                    height: 30px;
                }

                th:first-child {
                    width: 10vw;
                }

                th:nth-child(3) {
                    width: 25vw;
                }

                th:nth-child(2),
                th:nth-child(4) {
                    width: 5vw;
                }
            }

            tr:hover {
                background-color: var(--TABLE__HEADER__BG) !important;
            }
        }

        tbody {
            tr {
                td {
                    color: var(--TEXT__1);
                    padding: 0px 4px;
                    vertical-align: middle;
                }
            }

            tr.odd {
                background-color: var(--TABLE__ODD__BG) !important;
            }

            tr.even {
                background-color: var(--TABLE__EVEN__BG) !important;
            }

            tr:hover {
                td:not(.highlight) {
                    background-color: var(--TABLE__HOVER__BG) !important;
                }
            }
        }

        td,
        th {
            border: 1px solid var(--TABLE__BORDER__COLOR);
        }

        tr {
            height: 24px;
        }
    }
}

.bond-normal-table {
    overflow: auto;
    height: 100%;

    table {
        font-size: 0.65rem;
        width: 100%;
        border-spacing: 0;
        border-collapse: separate;

        thead,
        tfoot {
            tr:first-child {
                th {
                    height: 26px;
                    background-color: var(--TABLE__HEADER__BG);
                    position: sticky;
                    top: -1px;
                    z-index: 4;
                    text-align: center;
                    padding: 0px 4px;
                    white-space: nowrap;
                }
            }
            tr:nth-child(2) {
                th {
                    height: 26px;
                    background-color: var(--TABLE__HEADER__BG);
                    position: sticky;
                    top: 25px;
                    z-index: 4;
                    text-align: center;
                    padding: 0px 4px;
                    white-space: nowrap;
                }
            }


            tr:hover {
                background-color: var(--TABLE__HEADER__BG) !important;
            }
        }

        tbody {
            tr {
                td {
                    color: var(--TEXT__1);
                    padding: 0px 4px;
                }
            }

            tr:nth-child(even) {
                background-color: var(--TABLE__EVEN__BG);
            }

            tr:nth-child(odd) {
                background-color: var(--TABLE__ODD__BG);
            }

            tr:hover {
                background-color: var(--TABLE__HOVER__BG) !important;
            }
        }

        td,
        th {
            border: 1px solid var(--TABLE__BORDER__COLOR);
        }

        tr {
            height: 24px;
        }
    }
}


.bond-box {
    padding: 8px;
    position: relative;
    // top: .25rem;
    // bottom: .25rem;
    margin: 15px 0 15px 0;

    // width: fit-content;
    border: 1px solid var(--INPUT__BORDER);
    border-radius: .15rem;

    .float-lable {
        position: absolute;
        top: -10px;
        margin-left: 16px;
        padding: 0px 8px;
        background: var(--BG__1);
    }
}

.bond-stats {
    min-height: 45px;

    div {
        span {
            width: calc(100% / 3);
        }
    }

    div:nth-child(2) {
        span {
            font-weight: bold;
        }
    }
}

.bond-list {
    overflow: auto;
    // flex: auto;
    // min-height: 0;
    height: 100%;

    // position: relative;
    table {
        font-size: 0.75rem;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        thead {

            tr {
                th {
                    background-color: var(--TABLE__HEADER__BG);
                    position: sticky;
                    top: 0px;
                    padding: 0.5rem 1.5rem;
                    white-space: nowrap;
                    width: calc(100% / 3);
                }
            }

            tr:not(:last-child) {
                border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
            }
        }

        tbody {
            tr {
                td {
                    color: var(--TEXT__1);
                    padding: 0.5rem 1.5rem;
                }
            }

            tr:hover {
                background-color: var(--PRIMARY__BG__COLOR);
            }

            tr {
                border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
                height: 24px;
            }
        }

        tfoot {
            tr {
                background-color: var(--TABLE__HEADER__BG);
                color: var(--CEIL__COLOR);

                th {
                    padding: 0.5rem 1.5rem;
                    white-space: nowrap;
                }
            }

            tr:not(:last-child) {
                border-bottom: 2px solid var(--TABLE__BORDER__COLOR);
            }
        }

        tr:hover {
            background-color: var(--TABLE__HOVER__BG) !important;
        }
    }
}

.order-hist-table {
    overflow-x: auto;
    overflow-y: auto;
    font-size: 0.65rem;

    table {
        border-collapse: separate;
        border-spacing: 0;

        th {
            height: 26px;
            position: sticky;
            z-index: 4;
            background-color: var(--TABLE__HEADER__BG);
            top: -1px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            color: var(--TEXT__1);
        }
        td {
            height: 26px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            color: var(--TEXT__1);
        }
    }
}

.merge-right {
    border-right: none !important;
    // background-color: var(--PLACEHODLER__COLOR) !important;
}

.merge-left {
    border-left: none !important;
    // background-color: var(--PLACEHODLER__COLOR) !important;
}

.bond-float-button {
    position: relative;
    top: -15px;
    left: -15px;
    height: 0px;
    background-color: transparent !important;
}

.bond-investor-box {
    border: 1px solid var(--INPUT__BORDER);
    display: flex;
    flex-direction: column;
}

.upload-file {
    input[type="file"] {
        display: none;
    }
    min-width: 6.5rem;
    height: 1.688rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    background-color: var(--SELL__BG);
    color: var(--BUTTON__TEXT__PRIMARY);
    cursor: pointer;
    outline: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid var(--PRIMARY);
    }
}

.attach-file-box {
    width: 100%;
    height: 2rem;
    border: 1px solid var(--INPUT__BORDER);
    border-radius: 0.25rem;
    &:hover {
        border: 1px solid var(--PRIMARY);
    }
}

.disable-datepicker {
    opacity: 1 !important;
    .ant-picker-input > input[disabled] {
        opacity: 1 !important;
        color: var(--TEXT__1);
    }
}

.order-history,
.cash-flow,
.modal-contract {
    .ant-tabs,
    .ant-tabs-content-holder,
    .ant-tabs-content,
    .ant-tabs-tabpane {
        height: 100%;
    }
}