.stock__transfer {
    .list-code-stock {
        .ant-table-tbody > tr > td {
            padding: 8px 16px;
        }
    }
    .card-stock {
        border-radius: 8px;
        border: 1px solid VAR(--INPUT__BORDER);
        background: VAR(--BG__2);
    }
    .card-stock-left {
        height: 100%;
        .ant-card-body {
            padding: 0.5rem;
        }
    }
    .card-stock-right-top {
        height: 47%;
        .table-card-stock-right-top {
            // height:27vh;
            overflow-y: auto;
            max-height: calc(100vh - 76vh);
            // height: 10vh;
        }
        .ant-card-body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0.7rem;
        }
        .ant-table {
            background-color: var(--TABLE__EVEN__BG);
            color: var(--TEXT__1);
            border-top: 1px solid VAR(--INPUT__BORDER);
            border-right: 1px solid VAR(--INPUT__BORDER);
            border-left: 1px solid VAR(--INPUT__BORDER);

            .ant-table-thead > tr > th {
                background: var(--TABLE__HEADER__BG);
                color: var(--TEXT__1);
                border: none;
                border-bottom: 1px solid var(--INPUT__BORDER);
                height: 27px;
            }
            .ant-table-tbody > tr > td {
                background: VAR(--BG__2) !important;
                color: var(--TEXT__1);
                border: none;
                border-bottom: 1px solid var(--INPUT__BORDER);
                height: 27px;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #ffffff;
            outline: none;
            border: 0;
        }
        .ant-checkbox-checked .ant-checkbox-inner:after {
            // background-color: #ffffff;
            border-color: black;
        }
        .ant-checkbox-checked .ant-checkbox-input {
            border-color: black;
        }
    }
    .card-stock-right-bottom {
        height: 51.5%;
        // min-height: 249px;
        .ant-card-body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0.2rem;
        }
        .table-card-stock-right-bottom {
            .table-card-stock-content {
                overflow: auto;
                max-height: calc(100vh - 75vh);
                border: 1px solid var(--INPUT__BORDER);
                th {
                    height: 35px;
                    position: sticky;
                    z-index: 4;
                    background-color: var(--TABLE__HEADER__BG);
                    top: 0px;
                    border-left: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
                td {
                    height: 30px;
                    border: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
            }
        }
    }
}
.list-move-stock{
    overflow-y: auto;
    min-height: 15vh;
    max-height: 30vh;
}
