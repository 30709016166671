/**
* Dùng style navbar
*
*
*/


.cpn__navbar__header {
    background-color: var(--BG__1);

    .another__class {
        
    }
}