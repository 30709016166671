/**
* Dùng style priceboard
* NTTam coding
*
*/
.priceBoardPageCtn {
    display: flex;
    flex-direction: column;
    height: 100%;
    .div_table_prcb {
        overflow: hidden;
        overflow-x: auto;
    }
}
.div_table_prcb {
    width: 100%;
    overflow: auto;
    min-width: 1024px;
    position: relative;
    // height: calc(100vh - 96px - 184px);
    // padding: 0 1px 1px;
    // margin-top: 1px;
}
.puthroughTitle {
    color: var(--TEXT__1);
}

#div_table_prcb {
    font-family: Helvetica, Arial, sans-serif !important;
    min-width: 1022px;
    // tbody {
    //     font-family: tahoma, helvetica, arial, sans-serif;
    // }
}
.puthrough_table_prcb {
    font-family: Helvetica, Arial, sans-serif !important;
    overflow: hidden;
    position: relative;
    height: auto;
}
.table_price_board {
    position: relative;
    width: 100%;
    border-spacing: 0;
    // font-family: 'Open Sans';
    border-collapse: unset;
    margin-bottom: 250px;
    tbody tr td > span {
        padding: 1px 2px 1px 1px;
        white-space: nowrap;
    }
    thead {
        background-color: var(--TABLE__BORDER__COLOR);
        z-index: 1;
        // th {
        //     cursor: ns-resize;
        // }
    }
    thead tr th,
    tbody tr td {
        box-sizing: border-box;
        // font-family: tahoma, helvetica, arial, sans-serif;
    }
    thead tr th:nth-child(1), tbody tr td:nth-child(1), tfoot tr td:nth-child(1), thead#table_price_board_head.header tr:nth-child(2) th:nth-child(1) {
        border-left: 1px solid var(--TABLE__BORDER__COLOR);
    }
    thead#table_price_board_head tr:nth-child(2) th:nth-child(1) {
        border-left: unset;
    }
    tbody tr td,
    tfoot tr td {
        border: none;
        border-right: 1px solid var(--TABLE__BORDER__COLOR);
        border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
        text-align: right;
    }
    tbody tr td.price-down {
        text-shadow: var(--DOWN__COLOR) 0.1px 0.1px 0.1px;
    }
    td.fs-smallest,
    th.fs-smallest {
        font-size: 0.6rem;
    }
    td.nowrap,
    th.nowrap {
        white-space: nowrap;
    }
    tbody tr td.ceil_color,
    tbody tr td.price-ceil {
        text-shadow: var(--CEIL__COLOR) 0.1px 0.1px 0.1px;
    }
    tbody tr td.floor_color {
        text-shadow: var(--FLOOR__COLOR) 0.1px 0.1px 0.1px;
    }
    thead tr:nth-child(1) th:nth-child(1) {
        border-top: 1px solid var(--TABLE__BORDER__COLOR);
    }
    thead tr th {
        padding: 0;
        background-color: var(--TABLE__HEADER__BG);
        font-size: 0.8rem;
        font-weight: normal;
        border-spacing: 0;
        border-right: 1px solid var(--TABLE__BORDER__COLOR);
        z-index: 1;
    }
    .cover_warrent tr th {
        border-right: 1px solid var(--TABLE__BORDER__COLOR);
    }
    thead tr th.header_colspan {
        border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
    }
    thead tr th.th_r2 {
        position: sticky;
        top: 22px;
    }
    thead tr th.th_top {
        height: 20px;
        position: sticky;
        z-index: 1;
        top: 0;
    }
    .ceil_color {
        color: var(--CEIL__COLOR);
        text-shadow: currentColor 0.2px 0.2px 0.2px;
    }
    .ref_color {
        color: var(--REF__COLOR);
        text-shadow: currentColor 0.2px 0.2px 0.2px;
    }
    .floor_color {
        color: var(--FLOOR__COLOR);
        text-shadow: currentColor 0.2px 0.2px 0.2px;
    }
    .tr_price_board {
        height: 30px;
        align-items: center;
    }
    .tr_price_board:hover td,
    .tr_price_board.active td {
        background-color: var(--TABLE__HOVER__BG) !important;
    }
    .tr_price_board.active td {
        background-color: var(--TABLE__HOVER__BG);
    }
    .stk_nm {
        //-- base 14px
        font-style: normal;
        // font-weight: 600;
        padding-left: 4px;
        padding-right: 4px;
        text-align: left;
        min-width: 4rem;
        position: relative;
    }
    .issuer_class {
        text-align: center;
    }
    .stk_nm_font_md {
        font-size: 0.9rem;
    }
    .stk_nm_font_sm {
        font-size: 0.8rem;
    }
    tr td.td_number {
        text-align: right;
        font-size: 0.8rem;
    }
    tr td.td_price {
        min-width: 2.8rem;
    }
    tr td.td_volumn {
        min-width: 3.4rem;
    }
    tr td.td_total_val {
        min-width: 3.6rem;
    }
    tr td.pointerCls {
        cursor: pointer;
    }
    .bkg_thead_price_board {
        background-color: var(--TABLE__HEADER__BG);
    }
    .bkg_body_price_board {
        background-color: var(--BG__3);
    }
    tr.even_row_price_board {
        background-color: var(--TABLE__EVEN__BG);
    }
    tr.odd_row_price_board {
        background-color: var(--TABLE__ODD__BG);
    }
    td.highlight_cell {
        background-color: var(--HIGHTLIGHT_PB);
    }
    tr td.bk_blue {
        color: white !important;
        background-color: var(--BG__UP__COLOR) !important;
    }
    td.bk_red {
        color: white !important;
        background-color: var(--BG__DOWN__COLOR) !important;
    }
    td.bk_ref {
        color: white !important;
        background-color: var(--REF__BG) !important;
    }
    td.bk_neutral {
        color: white !important;
        background-color: var(--BG__NEUTRAL__COLOR) !important;
    }
    td.bk_neutral_ssv {
        background-color: var(--BG__NEUTRAL__COLOR) !important;
    }
    

    .removeStkIcon {
        visibility: hidden;
        display: inline;
        cursor: pointer;
        position: absolute;
        right: 2px;
    }
    .pinStkIcon {
        position: absolute;
        right: 1px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        svg {
            width: 12px;
            height: 12px;
        }
    }
    td.stk_nm:hover .removeStkIcon {
        visibility: visible;
    }
}
.table_price_board.table-suggest {
    tbody tr td,
    tfoot tr td {
        border: none;
        border-right: 1px solid var(--TABLE__BORDER__COLOR);
        border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
    }
}

.menu_price_board {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: Space-between;
    min-width: 1024px;
    height: 40px;
    align-items: flex-end;
    .menu_price_board_left {
        display: flex;
        flex-direction: row;
        justify-content: Flex-start;
        align-items: flex-end;
    }
    .menu_price_board_right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .ant-menu-horizontal,
    .ant-menu-vertical > .ant-menu-item {
        line-height: 32px;
        // height: 26px;
        border-bottom: unset !important;
        background-color: transparent;
        li {
            color: var(--TEXT__1);
        }
    }
    span.anticon {
        margin-right: 0;
    }
    // span.notChildItem {
    //     margin: 0 -0.5rem !important;
    // }
    ul.in_menu_price_board {
        width: 100%;
    }
    ul.in_menu_price_board li {
        margin: 0 2px !important;
        border-radius: 6px 6px 0 0;
        background-color: var(--TABLE__HEADER__BG);
    }
    ul.in_menu_price_board li span:hover {
        color: white !important;
        border-bottom: unset;
    }
    ul.in_menu_price_board .ant-menu-submenu:hover,
    ul.in_menu_price_board .ant-menu-item:hover {
        background-color: var(--PRIMARY) !important;
        border-bottom: unset !important;
    }
    ul.in_menu_price_board .ant-menu-submenu:hover .ant-menu-submenu-title,
    ul.in_menu_price_board .ant-menu-item:hover .ant-menu-submenu-title {
        color: #FFF;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        position: relative;
        display: inline-block;
        border-bottom: unset !important;
    }

    ul.in_menu_price_board .ant-menu-submenu-selected,
    ul.in_menu_price_board .ant-menu-item-selected {
        background-color: var(--PRIMARY) !important;
        border-bottom: unset !important;
        color: white !important;
    }

    li div.ant-menu-submenu-title {
        margin: 0 5px;
    }
}

li div.menu_item_action {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.menu_price_clear_both {
    width: 100%;
    // height: 3px;
    background-color: var(--PRIMARY);
    clear: both;
    margin-top: -0.5px;
    z-index: 1;
    border-top: 2px solid var(--PRIMARY);
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: var(--BG__1);
    border: 1px solid var(--BORDER__MODAL);
    li {
        color: var(--TEXT__1);
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--PRIMARY) !important;
    color: white !important;
}

.ant-menu-item:hover {
    background-color: var(--PRIMARY) !important;
    color: white !important;
}

.div_table_prcb_puth {
    display: flex;
    flex-direction: row;
    justify-content: Space-between;
    position: relative;
    height: calc(100% - 40px);
    overflow: auto;
    .sellbuyPuthrough {
        width: 27%;
    }
    .matchedPuthrough {
        width: 46%;
    }
    .th_top {
        border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
    }
}

.table_virtual_price_board {
    font-size: 0.8rem;
    .tr_price_board {
        // border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
    }
    .even_row_price_board {
        background-color: var(--TABLE__EVEN__BG);
    }
    .odd_row_price_board {
        background-color: var(--TABLE__ODD__BG);
    }
    .ReactVirtualized__Table__rowColumn:nth-child(1) {
        border-left: 1px solid var(--TABLE__BORDER__COLOR);
    }
    .ReactVirtualized__Table__rowColumn {
        padding: 5px 2px;
        border-right: 1px solid var(--TABLE__BORDER__COLOR);
        white-space: nowrap;
        text-align: right;
    }
    .ReactVirtualized__Table__rowColumn.t55 {
        text-align: left;
    }
    .ReactVirtualized__Table__rowColumn.t31,
    .ReactVirtualized__Table__rowColumn.t32,
    .ReactVirtualized__Table__rowColumn.t31_incr,
    .ReactVirtualized__Table__rowColumn.t31_incr_per,
    .ReactVirtualized__Table__rowColumn.t631,
    .ReactVirtualized__Table__rowColumn.t266,
    .ReactVirtualized__Table__rowColumn.t2661,
    .ReactVirtualized__Table__rowColumn.t260,
    .ReactVirtualized__Table__rowColumn.t333,
    .ReactVirtualized__Table__rowColumn.t332,
    .ReactVirtualized__Table__rowColumn.t31_PO,
    .ReactVirtualized__Table__rowColumn.t32_PO,
    .ReactVirtualized__Table__rowColumn.t31_incr_PO,
    .ReactVirtualized__Table__rowColumn.t31_incr_per_PO,
    .ReactVirtualized__Table__rowColumn.t631_PO,
    .ReactVirtualized__Table__rowColumn.t266_PO,
    .ReactVirtualized__Table__rowColumn.t2661_PO,
    .ReactVirtualized__Table__rowColumn.ceil_bond,
    .ReactVirtualized__Table__rowColumn.floor_bond,
    .ReactVirtualized__Table__rowColumn.ref_bond,
    .ReactVirtualized__Table__rowColumn.lowest_price_match_bond,
    .ReactVirtualized__Table__rowColumn.total_qty_lowest_price_match_bond,
    .ReactVirtualized__Table__rowColumn.price_match_bond,
    .ReactVirtualized__Table__rowColumn.qty_match_bond,
    .ReactVirtualized__Table__rowColumn.highest_price_match_bond,
    .ReactVirtualized__Table__rowColumn.total_qty_highest_price_match_bond,
    .ReactVirtualized__Table__rowColumn.total_qty_bond,
    .ReactVirtualized__Table__rowColumn.total_value_bond
    {
        background-color: var(--HIGHTLIGHT_PB);
    }

    .ReactVirtualized__Table__rowColumn.U10,
    .ReactVirtualized__Table__rowColumn.t106,
    .ReactVirtualized__Table__rowColumn.U24,
    .ReactVirtualized__Table__rowColumn.U23 {
        text-align: center;
    }
    .ReactVirtualized__Table__rowColumn.industries {
        text-align: left;
    }
    .ReactVirtualized__Table__row.tr_price_board.active,
    .ReactVirtualized__Table__row.tr_price_board:hover {
        background-color: var(--TABLE__HOVER__BG) !important;
    }

    .bk_blue {
        background-color: var(--BG__UP__COLOR) !important;
        span {
            color: white !important;
        }
    }
    .bk_red {
        span {
            color: white !important;
        }
        background-color: var(--BG__DOWN__COLOR) !important;
    }
    .bk_ref {
        span {
            color: white !important;
        }
        background-color: var(--REF__BG) !important;
    }
    .bk_neutral {
        span {
            color: white !important;
        }
        background-color: var(--BG__NEUTRAL__COLOR) !important;
    }

    .bk_neutral_ssv {
        background-color: var(--BG__NEUTRAL__COLOR) !important;
    }

    .removeStkIcon {
        visibility: hidden;
        display: inline;
        cursor: pointer;
        position: absolute;
        right: 2px;
    }
    .pinStkIcon {
        position: absolute;
        right: 1px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        svg {
            width: 12px;
            height: 12px;
        }
    }
    .ReactVirtualized__Table__rowColumn.t55:hover .removeStkIcon {
        visibility: visible;
    }
    .ReactVirtualized__Table__headerColumn {
        text-align: center;
        border-right: 1px solid var(--TABLE__BORDER__COLOR);
        // border-left: 1px solid var(--TABLE__BORDER__COLOR);
    }
    .ReactVirtualized__Table__headerRow.tr_price_board {
        background-color: var(--TABLE__HEADER__BG);
        font-size: 0.8rem;
        font-weight: normal;
        text-transform: unset;
    }

    .ReactVirtualized__Table__headerColumn.t55,
    .ReactVirtualized__Table__headerColumn.U10,
    .ReactVirtualized__Table__headerColumn.industries,
    .ReactVirtualized__Table__headerColumn.t391,
    .ReactVirtualized__Table__headerColumn.t387,
    .ReactVirtualized__Table__headerColumn.t3871,
    .ReactVirtualized__Table__headerColumn.t260,
    .ReactVirtualized__Table__headerColumn.t31,
    .ReactVirtualized__Table__headerColumn.t31_incr,
    .ReactVirtualized__Table__headerColumn.t31_incr_per,
    .ReactVirtualized__Table__headerColumn.pb,
    .ReactVirtualized__Table__headerColumn.pvbs,
    .ReactVirtualized__Table__headerColumn.eps,
    .ReactVirtualized__Table__headerColumn.bvps,
    .ReactVirtualized__Table__headerColumn.roe,
    .ReactVirtualized__Table__headerColumn.roa {
        padding: 10px 0;
    }
    .ReactVirtualized__Table__rowColumn.U21,
    .ReactVirtualized__Table__rowColumn.t106 {
        text-align: center;
        padding: 5px 0;
    }
    .ReactVirtualized__Table__rowColumn.U33 {
        padding: 5px 0;
        padding-right: 1px;
    }
    .ReactVirtualized__Table__rowColumn.t31_incr_per {
        padding: 5px 1px;
    }
    .ReactVirtualized__Table__row.tr_price_board_no_border {
        visibility: hidden;
    }
    .ReactVirtualized__Table__rowColumn {
        text-overflow: unset;
        border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
    }

    .ReactVirtualized__Table__rowColumn.c0,
    .ReactVirtualized__Table__rowColumn.c1,
    .ReactVirtualized__Table__rowColumn.c2,
    .ReactVirtualized__Table__rowColumn.c3 {
        text-align: left;
    }
    .ReactVirtualized__Table__rowColumn.action div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.priceboard-fund .ReactVirtualized__Table__headerRow {
    position: relative;
}

.ivs-border-left {
    border-left: 1px solid var(--LINE_MA5) !important;
}

.ivs-border-right {
    border-right: 1px solid var(--LINE_MA5) !important;
}

center {
    margin: 0px;
    padding: 0px;
    border: 0px;
    font: inherit;
    span.anticon {
        position: absolute;
        bottom: -3px;
    }
}

ul.in_menu_price_board .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 5px;
}

.table_public thead {
    position: sticky;
    top: 0px;
}

.invisible {
    visibility: hidden;
}

.priceboard-bonds-securities .ReactVirtualized__Table__headerTruncatedText {
    display: unset;
}