/**
* Dùng style header top
*
*
*/

.cpn__top__header {
    background-color: var(--BG__2);

    .another__class {
        
    }
}