.common_table_wrapper {
    overflow: auto;
    // visibility: hidden;
    visibility: visible;
    &:hover,
    &:focus {
        visibility: visible;
    }

    .common_table {
        min-height: auto;
        max-height: calc(100vh - 220px);
        border: 1px solid var(--INPUT__BORDER);
        width: 100%;
        visibility: visible;
        transition: visibility 0.2s;
        &:hover {
            transition: visibility 0s 0.2s;
        }
        .table_row_head {
            background-color: var(--TABLE__HEADER__BG);
        }
        .table_row_odd {
            background-color: var(--TABLE__ODD__BG);
        }
        .table_row_even {
            background-color: var(--TABLE__EVEN__BG);
        }
        .table_cell {
            height: 30px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        .thead_cell {
            height: 35px;
            position: sticky;
            z-index: 4;
            background-color: var(--TABLE__HEADER__BG);
            top: -2px;
            border-left: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

.index-overview-table {
    overflow: auto;
    &:hover,
    &:focus {
        visibility: visible;
    }
    .table_index_overview {
        width: 100%;
        visibility: visible;
        transition: visibility 0.2s;
        &:hover {
            transition: visibility 0s 0.2s;
        }
        .table_row_head {
            background-color: transparent;
        }
        .table_row_odd {
            background-color: transparent;
        }
        .table_row_even {
            background-color: transparent;
        }
        tr:hover {
            background-color: var(--TABLE__HOVER__BG) !important
        }
        tr .table_cell {
            flex: 1;
            align-items: center;
            flex-wrap: nowrap;
            padding: 0px 4px;
        }
        tr:not(:last-child) .table_cell {
            border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
        }
        .thead_cell {
            flex: 1;
            position: -webkit-sticky;
            position: sticky;
            top: 0rem;
            background-color: var(--BG__2);
            z-index: 4;
            align-items: center;
            font-weight: normal;
            padding: 4px 4px;
            flex-wrap: nowrap;
            border-bottom: 1px solid var(--TABLE__BORDER__COLOR);
            padding-top: 0.25rem;
            span {
                font-size: 0.8rem;
            }
        }
        tr:nth-child(even) td {
            background-color: transparent;
        }
    }
}
