.alt-input-textarea{
    .alt-label{
        font-weight: normal;
        font-size: 0.75rem;
        min-width: 4rem;
        // line-height: 18px;
        padding-bottom: 0
    }

    .alt-input{
        background: var(--BG__1);
        border: 0.5px solid var(--INPUT__BORDER);
        box-sizing: border-box;
        border-radius: 0.25rem;
        height: 2rem;
        min-width: calc( 100% - 8rem);
        box-shadow: none;
        font-size: 0.75rem;
    }

    .ant-picker:hover, .ant-picker-focused{
        border-color: var(--PRIMARY);
        border-right-width: 1px !important;
        outline: none;
    }

    ::placeholder {
        font-size: 0.75rem;
    }

    .ant-space{
        width: 100%;
        .ant-space-item:nth-child(2){
            width: 100%;
        }
    }
}