/**
* Dùng style navbar
*
*
*/

.btn_feedback{
    border-radius: 20px; 
    height: auto;
    padding: 0 20px;
}
.cpn__feedback__header {
    min-width:800px;
    min-height: 500px;
    // height:90%;
    .feedback_tabpane {
      padding: 8px;
      min-height: 30vh;
      max-height: 60vh;
      overflow-y: auto;
      color: var(--TEXT__1);
      .feedback_customer{
        justify-content: flex-end;
      }
      .content_feedback{
        background: var(--BG__3);
        border-radius: 8px;
        padding: 10px 10px 5px 10px;
        font-size: 14px;
        .content_feedback_des{
          // font-size: 14px; 
          padding-top: 5px;
        }
        .btn_reqly_feedback{
          cursor: pointer;
        }
        .content_feedback_time{
          padding-left: 20px;
        }
      }
      .feedback_image{
        margin-top: 5px;
        .content_feedback_image{
          border-radius: 8px !important;
          width: 90% !important;
          height: 90% !important;
        }
      }
    }
}
.form_create_feedback{
  width: 100%;
  height: 100%;
  .add_emoji_file{
    .cpn_emoji {
      font-size: 17px !important;
      cursor: pointer !important;
    }
  }
}
// .cpn__reply_feedback__header{
//   min-height: 500px;
//   min-width:800px;
// }
.reply_feedback{
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  .content_reqly_feedback{
    background: var(--BG__3);
    border-radius: 8px;
    padding: 10px 10px 5px 10px;
    color: var(--TEXT__1) ;
    .content_reqly_feedback_des{
      font-size: 12px;
      // color: var(--TEXT__2) ;
    }
  }
}
