.assets {
  font-size: 0.875rem;
  .ant-divider-horizontal {
    margin: 0;
  }
  .ant-divider-vertical {
    margin: 0 4px;
    height: 100%;
  }

  .chart-assets {
    .highcharts-point.highcharts-color-0 {
      stroke: transparent;
    }
    .highcharts-point.highcharts-color-0:nth-child(1) {
      fill: var(--REF__COLOR);
    }
    .highcharts-point.highcharts-color-0:nth-child(2) {
      fill: var(--STOCK__COLOR);
    }
    .highcharts-point.highcharts-color-0:nth-child(3) {
      fill: var(--TOTAL_ASSETS__COLOR);
    }
    .highcharts-point.highcharts-color-0:nth-child(4) {
      fill: var(--DEBT__COLOR);
    }
    .highcharts-point.highcharts-color-0:nth-child(5) {
      fill: var(--NET_ASSET__COLOR);
    }
  }

  .ant-tabs {
    flex: 1;
    font-size: 0.875rem;
  }
  .ant-tabs-nav-wrap {
    background-color: var(--SECOND__BG__COLOR);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: unset;
  }
  .table {
    font-size: 0.75rem;
    .border-right {
      border-right: 1px solid var(--BORDER__COLOR);
    }
    .head {
      background-color: var(--TABLE__HEADER__BG);
    }
  }
  .ant-tabs-content {
    height: 100%;
  }
}

.tooltip-300 {
  max-width: 350px;
  min-width: 280px;
  font-size: 0.75rem;
}
.tooltip-300 .flex.justify-content-between:nth-child(odd){
  border-bottom: 1px dashed var(--TEXT__2);
}
.tooltip-300 .flex.justify-content-between:nth-child(even){
  border-bottom: 1px dashed var(--TEXT__3);
}
.tooltip-min {
  min-width: 200px;
}

.time-options {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 130px;
  background: var(--BG__2);
  border: 0.5px solid var(--INPUT__BORDER);
  border-radius: 0.25rem;
  margin-right: 16px;
  span {
    cursor: pointer;
  }
}

.assets-list table thead tr:first-child th {
  position: sticky;
  top: -1px;
  background-color: var(--TABLE__HEADER__BG);
  z-index: 4;
}
.assets-list table thead tr:nth-child(2) th {
  position: sticky;
  top: 23px;
  background-color: var(--TABLE__HEADER__BG);
  z-index: 4;
}
.assets-list table tfoot tr:first-child th {
  position: sticky;
  bottom: -1px;
  background-color: var(--TABLE__HEADER__BG);
  z-index: 4;
}

#chart_stock .highcharts-pie-series  text {
  fill: var(--PRIMARY__CONTENT__COLOR)!important;
}

#chart_assets_weight {
  .highcharts-series-0 .highcharts-point {
    fill: #AFACFC;
  }
  .highcharts-series-1 .highcharts-point {
    fill: #EEAFFD;
  }
}

#chart_profit_loss_index_tab1, #chart_profit_loss_index_tab2 {
  input {
    display: none;
    cursor: default;
  }
  .highcharts-label.highcharts-range-input {
    cursor: default!important;
  }
}

#chart_profit_loss_index_tab2 .highcharts-legend {
  .highcharts-color-1.highcharts-series-1 {
    path {
      stroke: var(--REF__COLOR);
    }
  }
}
#chart_profit_loss_index_tab2 .highcharts-series-group .highcharts-series-0, 
#chart_statistic .highcharts-series-group .highcharts-series-0 {
  rect {
    stroke: transparent;
  }
}

body.DARK {
  .highcharts-range-selector-group {
    .highcharts-button-normal {
      rect {
        fill: #505053!important;
      }
      text {
        fill: #CCC!important;
      }
    }
    .highcharts-button-pressed {
      rect {
        fill: #000003!important;
      }
      text {
        fill: #FFF!important;
      }
    }
    .highcharts-button-disabled {
      rect {
        fill: transparent!important;
      }
    }
  }
}
