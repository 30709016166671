.tble-normal{
            overflow-x: auto;
            overflow-y: auto;
            min-height: auto;
            max-height: 25vh;
            th {
                height: 35px;
                position: sticky;
                z-index: 4;
                background-color: var(--TABLE__HEADER__BG);
                top: -2px;
                border: 1px solid var(--INPUT__BORDER);
                white-space: nowrap;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                color: var(--TEXT__1);
            }
            td {
                height: 30px;
                border: 1px solid var(--INPUT__BORDER);
                white-space: nowrap;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                color: var(--TEXT__1);
            }
}