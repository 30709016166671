.bank__withdrawal {
    .card-bank-withdrawal {
        border-radius: 8px;
        border: 1px solid VAR(--INPUT__BORDER);
        background: VAR(--BG__2);
    }
    .left-bank-withdrawal {
        height: 100%;
        .ant-card-body {
            padding: 0.5rem;
        }
    }
    .right-bank-withdrawal {
        height: 100%;
        .ant-card-body {
            padding: 0 0 0.5rem 0;
        }
        .table-bank-withdrawal-right {
            .table-bank-withdrawal-right-content {
                overflow: auto;
                max-height: calc(100vh - 250px);
                border: 1px solid var(--INPUT__BORDER);
                th {
                    height: 35px;
                    position: sticky;
                    z-index: 4;
                    background-color: var(--TABLE__HEADER__BG);
                    top: 0px;
                    border-left: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
                td {
                    height: 30px;
                    border: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
            }
        }
    }
}
