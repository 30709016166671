/**
* Overide highcharts
*
*
*/

.highcharts-background {
  fill: transparent;
}

.highcharts-series-1 {
  stroke-dasharray: 4, 6;
}

.highcharts-credits {
  fill: #ffffff00 !important;
  color: #ffffff00 !important;
  font-size: 0.1px !important;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0.5px;
  stroke: #ce9b5120;
}
.highcharts-yaxis-grid .highcharts-grid-line {
  stroke-width: 0.5px;
  stroke: #ce9b5120;
}
.highcharts-axis-line,
.highcharts-tick {
  stroke-width: 0.5px;
  stroke: #ce9b5120;
}

.highcharts-text-outline {
  display: none;
}

// .highcharts-tooltip span {
//   background-color: white;
//   border: 1px solid green;
//   border-radius: 3px;
//   opacity: 1;
//   z-index: 9999 !important;
//   padding: 5px;
// }

// .highcharts-point {
//   z-index: -1;
// }

.label_treemap {
  top: -28px !important;
  z-index: -1;
}
.label_treemap_item {
  z-index: 0;
  cursor: pointer;
}

// .highcharts-point .highcharts-internal-node {
//   border: 2px solid red;
//   stroke-width: 2px !important;
// }

////////////////////------------------

.ant-select-dropdown {
  color: var(--TEXT__1);
  background-color: var(--BG__2);
  border-radius: 0.5rem;
  border: 1px solid var(--INPUT__BG);
  box-shadow: var(--INPUT__BG) 0px 0px 30px;
  .ant-select-item {
    color: var(--TEXT__1);
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    background-color: var(--PRIMARY);
  }
  .ant-select-item-option-active {
    background-color: var(--PRIMARY);
    color: #fff;
  }
}

.cash-flow {
  .highcharts-graph.zone-up {
    stroke: var(--UP__COLOR);
  }
  .highcharts-area.zone-up {
    fill: var(--UP__COLOR);
  }
  
  .highcharts-graph.zone-down {
    stroke: var(--DOWN__COLOR);
  }
  .highcharts-area.zone-down {
    fill: var(--DOWN__COLOR);
  }
  .highcharts-line-series {
    .highcharts-graph.zone-up {
      stroke: transparent;
    }
    .highcharts-area.zone-up {
      fill: transparent;
    }
    .highcharts-graph.zone-down {
      stroke: transparent;
    }
    .highcharts-area.zone-down {
      fill: transparent;
    }
  }
  .highcharts-point {
    display: none;
  }
}


.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px;
}

svg text {
  font-family: Helvetica,Arial,Sans-Serif;
}
.highcharts-markers {
  display: none;
}