/**
* Dùng để define css cho modal dùng chung
*
*
*/


.common__modal {
    background-color: var(--BG__MODAL);
    .header__modal {
        font-size: var(--big);
    }
}