/**
* Dùng để define một số css chung như input, button, checkbox, switch, ...
*
*
*/

.alt-btn {
    min-width: 6.5rem;
    height: 1.688rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: var(--BUTTON__TEXT__PRIMARY);
    cursor: pointer;
    outline: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid var(--PRIMARY);
    }
}

.alt-btn-default {
    background-color: var(--INPUT__BG);
    color: var(--PRIMARY__CONTENT__COLOR);
}

.alt-btn-primary {
    background-color: var(--PRIMARY);
}

.alt-btn-cancel {
    background-color: var(--ERROR__COLOR);
}

.alt-label {
    font-weight: normal;
    font-size: 0.75rem;
    min-width: 4rem;
    padding-bottom: 0.25rem;
    color: var(--PRIMARY__CONTENT__COLOR);
}
.alt-input-label-horizontal {
    display: flex;
    .alt-label {
        display: flex;
        padding-bottom: 0rem;
        align-items: center;
        span {
            display: flex;
            align-items: center;
        }
    }
}
.alt-input-label {
    .alt-input {
        background: var(--BG__1) !important;
        border: 0.5px solid var(--INPUT__BORDER);
        box-sizing: border-box;
        border-radius: 0.25rem;
        height: 2rem;
        min-width: 9rem;
        box-shadow: none;
        font-size: 0.75rem;
    }
    
    .ant-input:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
    }
    .alt-input-label-required {
        color: var(--ERROR__COLOR);
        margin-left: 4px;
        font-size: var(--big);
        line-height: 0.3;
        vertical-align: bottom;
    }

    .ant-picker:hover,
    .ant-picker-focused {
        border-color: var(--PRIMARY);
        border-right-width: 1px !important;
        outline: none;
    }

    ::placeholder {
        // color: #FFFFFF;
        font-size: 0.75rem;
    }
}

.alt-input-select {
    .alt-label {
        font-weight: normal;
        font-size: 0.75rem;
        // line-height: 18px;
        min-width: 4rem;
    }

    .alt-input {
        height: 2rem;
        width: 100%;
        box-shadow: none;
        .ant-select-selector {
            background: var(--BG__1);
            border: 0.5px solid var(--INPUT__BORDER);
            box-sizing: border-box;
            border-radius: 0.25rem;
            cursor: pointer;

        }

        .alt-input-option {
            font-size: 0.75rem;
        }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        outline: none;
        box-shadow: none;
    }

    .ant-select-selection-placeholder {
        font-size: 0.75rem;
    }

    .ant-select-selector {
        font-size: 0.75rem;
    }

    .ant-select-clear {
        background: var(--BG__2);
    }

    .ant-select-item-option-active {
        background-color: var(--PRIMARY);
    }
}

/////////// Công
.alt-text-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    // border-color: var(--INPUT__BORDER) !important;
}

.ant-picker-cell .ant-picker-cell-inner {
    color: var(--PRIMARY__CONTENT__COLOR);
}

.ant-picker-header-view button {
    color: var(--PRIMARY__CONTENT__COLOR);
}

.alt-date-input {
    .alt-label {
        font-weight: normal;
        font-size: 0.75rem;
        min-width: 2rem;
        margin-right: 4px;
    }

    .alt-date-picker {
        background: var(--BG__1);
        border: 0.5px solid var(--INPUT__BORDER);
        box-sizing: border-box;
        border-radius: 0.25rem;
        height: 2rem;
        min-width: 9rem;
        box-shadow: none;
        input {
            background: var(--BG__1);
            font-size: 0.75rem;
        }
    }

    .ant-picker:hover,
    .ant-picker-focused {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        outline: none;
    }

    ::placeholder {
        font-size: 0.75rem;
    }
    .ant-picker-input > input[disabled] {
        color: var(--TEXT__1);
        opacity: 0.5;
    }
}
.ant-picker-header button {
    color: var(--PRIMARY__CONTENT__COLOR);
    &:hover {
        color: var(--PRIMARY__CONTENT__COLOR);
        opacity: 0.7;
    }
}
.ant-input-disabled {
    opacity: 0.5;
}