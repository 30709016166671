@font-face {
  font-family: "OneShinhan";
//   src: url("../../assets/fonts/shinhan-light.eot");
  src: local("OneShinhan"), url("../../assets/fonts/shinhan-light.woff") format("woff"),
    url(../../assets/fonts/shinhan-light.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "OneShinhan";
//   src: url("../../assets/fonts/shinhan-medium.eot");
  src: local("OneShinhan"), url("../../assets/fonts/shinhan-medium.woff") format("woff"),
    url(../../assets/fonts/shinhan-medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OneShinhan";
//   src: url("../../assets/fonts/shinhan-bold.eot");
  src: local("OneShinhan"), url("../../assets/fonts/shinhan-bold.woff") format("woff"),
    url(../../assets/fonts/shinhan-bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
