.row-input{
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tble-render-data{
    overflow: hidden;
    .ant-table-thead{
        .ant-table-cell{
            padding: 0.5rem 0.625rem
        }
    }
    
    td{
        font-size: 0.75rem;
    }

    .ant-table .ant-table-expanded-row-fixed{
        margin: 0;
        padding: 0
    }

    
}