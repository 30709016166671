// !!! {Dung} table
.order__infomation {
    .table-left-order-infomation {
        overflow:scroll;
        min-height: auto;
        max-height: calc(100vh - 280px);
        // max-height: 55vh;
        // min-width: auto;
        // width: 1000px;
        border: 1px solid var(--INPUT__BORDER);
        th {
            height: 35px;
            position: sticky;
            z-index: 4;
            background-color: var(--TABLE__HEADER__BG);
            top: 0px;
            border-left: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        td {
            height: 30px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
    .table-right-order-infomation {
        overflow:scroll;
        min-height: auto;
        max-height: calc(100vh - 280px);
        border: 1px solid var(--INPUT__BORDER);
        .first-th th{
            top: 0px;
        }
        .second-th th{
            top: 20px;
        }
        th {
            height: 20px;
            position: sticky;
            z-index: 4;
            background-color: var(--TABLE__HEADER__BG);
            border-left: 1px solid var(--INPUT__BORDER);
            border-bottom: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        td {
            height: 30px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}
