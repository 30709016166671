// .margin__extension {
//     .table-margin-extension {
//         overflow:scroll;
//         min-height: auto;
//         max-height: 55vh;
//         // min-width: auto;
//         // width: 1000px;
//         th {
//             height: 35px;
//             position: sticky;
//             z-index: 4;
//             background-color: var(--TABLE__HEADER__BG);
//             top: -2px;
//             border: 1px solid var(--INPUT__BORDER);
//             white-space: nowrap;
//             padding-left: 0.5rem;
//             padding-right: 0.5rem;
//         }
//         td {
//             height: 30px;
//             border: 1px solid var(--INPUT__BORDER);
//             white-space: nowrap;
//             padding-left: 0.5rem;
//             padding-right: 0.5rem;
//         }
//     }
// }
.margin__extension {
    .card-margin-extension {
        border-radius: 8px;
        border: 1px solid VAR(--INPUT__BORDER);
        background: VAR(--BG__2);
    }
    .card-margin-extension-left {
        height: 100%;
        // min-height: 520px;
        .ant-card-body {
            padding: 0.5rem;
        }
    }
    .card-margin-extension-right {
        height: 100%;
        .ant-card-body {
            padding: 0 0 0.5rem 0;
        }
        .table-card-margin-extension-right {
            .table-card-margin-extension-right-content{
                overflow: auto;
                max-height: calc(100% - 205px);
                border: 1px solid var(--INPUT__BORDER);
                th {
                    height: 35px;
                    position: sticky;
                    z-index: 4;
                    background-color: var(--TABLE__HEADER__BG);
                    top: 0px;
                    border-left: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
                td {
                    height: 30px;
                    border: 1px solid var(--INPUT__BORDER);
                    white-space: nowrap;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    color: var(--TEXT__1);
                }
            }
        }
    }
}

