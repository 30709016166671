html {
  font-size: 16px;
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--BG__1) !important;
  color: var(--TEXT__1);
  font-size: var(--normal);
  font-family: Helvetica, Arial, Sans-Serif;
  // font-family: OneShinhan, sans-serif;
  box-sizing: border-box;
  input {
    background-color: inherit;
  }
}
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--BG__1);
  display: flex;
  > div:first-child {
    display: flex;
    flex: 1;
  }
}

.loading-view {
  background-image: url(../assets/images/loading.gif);
  background-size: cover;
}

.menu__layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

// ---------- Ti style start----------
.header-bar-block {
  height: 48px;
  background-color: var(--BG__1);
  min-width: 1070px;
  display: flex;
  border-bottom: 1px solid var(--DIVIDER__COLOR);
  justify-content: space-between;
  .header-bar-col-logo-search {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    .logo-image {
      text-align: center;
      margin-right: 0.5rem;
    }
    .header-bar-search-component {
      // margin-top: 10px;
      .search-stk-auto-container {
        display: inline-flex;
        width: 297px;
        position: relative;
        padding: 0;
        ::-webkit-scrollbar {
          width: 4px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: var(--PRIMARY);
          border: 8px;
        }
        .react-autosuggest__input--focused {
          border: 0;
          outline: none;
        }
        .search-stk-button {
          border-left: 0;
          width: 22px;
          height: 22px;
          background: transparent;
          text-align: center;
          line-height: 22px;
          margin-right: 5px;
        }
      }
    }
  }
  .header-bar-col-timeserver {
    width: 100px;
    .wrapp-time-server {
      height: 48px;
      .time-server {
        // font-family: 'digital-fonts';
        font-size: 0.9rem;
        height: 48px;
        text-align: center;
        line-height: 48px;
        letter-spacing: 1px;
      }
    }
  }
  .header-bar-col-action {
    display: flex;
    justify-content: space-evenly;
    .item-right-head {
      margin: 0 0 0 0.5rem;
      .dropdown-lang-choose {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .switch-change-theme {
          min-width: 20px;
          height: 10px;
          line-height: 10px;

          .ant-switch-handle {
            width: 10px;
            height: 10px;
            top: 0;
            left: 0;
          }
        }
        .ant-switch-checked {
          background-color: #ce9b51 !important;
        }
        //   customize switch change theme
        .ant-switch-checked .ant-switch-handle {
          left: calc(100% - 8px - 2px) !important;
        }
        .title-lang-choose {
          width: auto;
          font-size: 0.75rem;
          margin-left: 5px;
        }
      }
      .justify-content-right {
        justify-content: flex-end;
      }
      .login-customize {
        display: flex;
        align-items: center;
        border-radius: 0.25rem;
        // border: 1.5px solid var(--PRIMARY);
        background-color: var(--PRIMARY);
        color: white;
        padding: 0 0.5rem;
        &:hover {
          // background: var(--BG__2);
          background: var(--PRIMARY);
        }
      }
      &:last-child {
        margin-right: 0.3rem !important;
      }
    }
  }
}

.menu-function-taskbar {
  width: 100%;
  font-size: 0.75rem;
  background-color: var(--BG__1);
  display: flex;
  box-shadow: 0px 4px 4px #00000040;
  li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected,
  li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected:hover {
    font-weight: bold;
    color: var(--TEXT__1) !important;
  }

  .menu-function-render {
    background: var(--BG__1);
    border-bottom: 0;
    color: var(--TEXT__1);
    .ant-menu-item-active {
      background-color: var(--BG__1) !important;
      color: var(--TEXT__1) !important;
    }

    li.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected span {
      font-weight: bold;
      color: var(--TEXT__1);
    }
  }
  .ant-menu {
    display: block;
    min-width: 1200px;
  }
}

.item-lang {
  display: flex;
  align-items: center;
  .title-lang-choose {
    width: 69px;
    font-size: 0.75rem;
    margin-left: 5px;
    // text-transform: uppercase;
    color: var(--TEXT__1);
  }
}

.item-lang-sub {
  display: flex;
  align-items: center;
  .title-lang-choose {
    width: 140px;
    font-size: 0.75rem;
    margin-left: 5px;
    color: var(--TEXT__1);
  }
}

.ant-dropdown-menu-item-active {
  .title-lang-choose {
    color: var(--TEXT__1);
  }
}

.notification-head {
  padding: 10px;
  display: flex;
  align-items: center;
  height: 52px;
  background-color: var(--BG__2);
  color: var(--TEXT__1);
  border-radius: 8px 8px 0 0;
  border: 1px solid var(--TEXT__TITLE__1);
  border-bottom: 1px solid var(--BORDER__CHECKBOX);
  .notification-head-left {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
  }
  .notification-head-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .notification-select-dropdown {
      background: var(--BG__1);
      width: 115px;
      border: 1px solid var(--BORDER__COLOR);
      padding: 2px 4px;
    }
  }
}

.notification-content {
  overflow-y: auto;
  max-height: calc(100% - 52px);
  padding: 5px 5px;
  background-color: var(--BG__2);
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--TEXT__TITLE__1);
  border-top: 0;
  .clear-all {
    padding: 0.25rem;
    color: var(--PRIMARY);
    cursor: pointer;
  }
  .item-notification {
    display: flex;
    position: relative;
    padding: 4px;
    border-radius: 8px;
    background: var(--BG__1);
    cursor: pointer;
    width: 100%;
    &:hover {
      background: #315067;
    }
  }
  .read {
    opacity: 0.65;
  }
  .un-read {
    opacity: 1;
  }

  .un-read.read {
    opacity: 0.65 !important;
  }

  .item-notification-content {
    .content-alert {
      font-size: 0.75rem;
      padding: 2px;
      margin-bottom: 5px;
      color: var(--TEXT__1);
    }
    .time-alert {
      font-size: 0.65rem;
      color: var(--TEXT__2);
    }
  }
  .notification-close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
  }
}

.option-select-notification {
  left: 1200px !important;
  .ant-dropdown-menu {
    padding: 0;
    width: 138px;
    background: var(--BG__2);
    li {
      color: var(--TEXT__1);
    }
  }
}

.tbl-style {
  thead {
    font-size: 0.75rem;
  }
}

.login-information div {
  color: var(--TEXT__1);
}

.login-information .info-li:hover,
.login-information .info-li:hover > * {
  color: orange;
  cursor: pointer;
}

.title-header-config {
  color: var(--TEXT__1);
}

.title_header-s {
  color: var(--TEXT__TITLE__1);
  font-weight: bold;
  margin-right: 10px;
  font-size: 0.85rem;
  cursor: pointer;
}

.wrapper-options {
  padding-left: 10px;
  .option-config {
    color: var(--TEXT__1);
  }
}
.ant-modal-content {
  border: 1px solid var(--TEXT__TITLE__1);
}

.modal-functional-customize-v2 {
  top: 5px !important;
  .ant-modal-content {
    .ant-modal-body {
      max-height: calc(100vh - 10px) !important;
    }
  }
}

.modal-functional-customize {
  color: var(--TEXT__1);
  padding: 0;
  top: 0.625rem !important;
  min-width: 1334px;
  width: calc(100vw - 48px) !important;
  .ant-modal-content {
    border-radius: 0;
    background-color: var(--BG__1);
    border: 1px solid var(--TEXT__TITLE__1);
    border-radius: 0.5rem;

    .ant-modal-header {
      background-color: var(--BG__1);
      text-align: center;
      text-transform: uppercase;
      border: 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding: 0;
      .drag_title {
        color: var(--TEXT__1);
      }
    }
    .ant-modal-body {
      padding: 0;
      background-color: var(--BG__1);
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      .div_table_prcb {
        height: unset;
        padding: 0;
        overflow: hidden;
      }
      th,
      tr {
        text-align: center;
        color: var(--TEXT__1);
      }

      .industry-table {
        margin-top: 1rem;
        td {
          background-color: transparent !important;
        }
      }
    }
    .ant-modal-close {
      display: block;
      color: var(--TEXT__1);
    }

    .news-events {
      // margin-right: 1rem;
    }

    // .ant-tabs-nav-wrap {
    //     padding-left: 1rem;
    // }

    .close-icon-modal {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      cursor: pointer;
    }
  }
}

.modal-major {
  color: var(--TEXT__1);
  min-width: 1298px;
  padding: 0;
  top: 46px !important;

  .ant-modal-content {
    border-radius: 0;
    background-color: var(--BG__1);
    border: 1px solid var(--TEXT__TITLE__1);
    border-radius: 0.5rem;
    .ant-modal-header {
      background-color: var(--BG__1);
      text-align: center;
      text-transform: uppercase;
      border: 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      .drag_title {
        color: var(--TEXT__1);
      }
    }
    .ant-modal-body {
      padding: 0 1rem 1rem 1rem;
      background-color: var(--BG__1);
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      // max-height: calc(100vh - 125px);
      overflow-y: auto;
    }
    .ant-modal-close {
      display: block;
      color: var(--TEXT__1);
    }
  }

  .ant-input {
    // border: 1px solid var(--BORDER__COLOR);
    box-sizing: border-box;
    border-radius: 0.25rem;
    box-shadow: none;
    outline: none;
  }
  .ant-input:hover {
    border-color: #40a9ff;
  }

  .close-icon-modal {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
  }

  .ant-input {
    // border: 1px solid var(--BORDER__COLOR);
    box-sizing: border-box;
    border-radius: 0.25rem;
    box-shadow: none;
    outline: none;
  }

  .close-icon-modal {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
  }
}

.ctx {
  top: 180px !important;
}
.ctx-1 {
  top: 100px !important;
}

//   overwrite antd popover

.ant-popover-arrow {
  display: none;
}

.ant-dropdown-menu-submenu-expand-icon {
  display: none;
}

.ant-popover-inner {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.ant-dropdown-menu-submenu-popup ul {
  background-color: var(--BG__1);
  color: var(--TEXT__1);
}

.ant-dropdown-menu-item:hover {
  color: var(--TEXT__1);
  background-color: var(--HOVER__BG__1);
}

.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--HOVER__BG__1);
}

// .ant-input-affix-wrapper {
//   background-color: var(--PRIMARY__BG__COLOR) !important;
//   border: 1px solid var(--BORDER__COLOR);
// }

.customize-dropdown-user {
  top: 2.938rem !important;
}

.notify-customize {
  top: 48px !important;
  padding: 0 !important;
  .ant-popover-inner {
    background-color: transparent;
  }
}

.customzie-dropdown-lang {
  top: 3rem !important;
}

.header-stock-info {
  min-width: 1263px;
  padding: 1rem 0.625rem 0.625rem 0;
  align-items: center;
  .btn-stock-info-change {
    border-radius: 8px;
    border: 0;
    // transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    outline: none;
    background-color: #e8e8e8;
    color: #9ca4af;
    transition: 0.5s ease-out;
    width: 50%;
  }
  .active {
    transform: scaleY(1.1);
    color: #fff;
    background-color: #0f70b8;
  }
  .btn-add-watch-list {
    border-radius: 8px;
    background-color: #0f70b8;
    width: 13.063rem;
  }
}

::-webkit-scrollbar-thumb {
  background-color: var(--TEXT__2);
  border: 8px;
}

/*** Report request major scss *****/
.report-request-major-title {
  text-transform: uppercase;
  background-color: var(--PRIMARY);
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
  text-align: center;
}

// ---------- Ti style end----------

.icon_class {
  color: var(--TEXT__1);
  font-size: var(--big);
  // padding-top: 12px;
  padding-bottom: 6px;
}

.full_width {
  width: 100%;
}

.bk_blue {
  color: #fff !important;
  background-color: blue;
  transition: color ease 0.3s;
}
.bk_red {
  color: #fff !important;
  background-color: red;
  transition: color ease 0.3s;
}

.table_market_analytics {
  background-color: var(--BG__2);
  border-radius: 16px;
  border: 1px solid var(--INPUT__BORDER);
  font-size: var(--xnormal);
}
.chart_market_analytics {
  background-color: var(--BG__2);
  border-radius: 16px;
  border: 1px solid var(--INPUT__BORDER);
  font-size: var(--xnormal);

  .title_group_treemap {
    text-align: center;
    position: relative;
    background: var(--INPUT__BG);
    color: var(--TEXT__1);
    text-align: center;
    font-size: 0.85rem;
    font-weight: 600;
    padding: 8px;
    display: block;
    &:first-child {
      border-right: 2px solid var(--BG__2);
      border-left: 2px solid var(--BG__2);
    }
    // border: 1px solid var(--PRIMARY);
  }

  // .title_group_treemap::after {
  //     content: '';
  //     position: absolute;
  //     top: 100%;
  //     left: 0;
  //     right: 80%;
  //     margin: 0 auto;
  //     width: 0;
  //     height: 0;
  //     border-top: solid 8px var(--INPUT__BG);
  //     border-left: solid 8px transparent;
  //     border-right: solid 8px transparent;
  // }
}

/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

// input.padding-input {
//   padding-top: 4px;
//   padding-bottom: 4px;
// }

// UI input
.ui-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  background-color: var(--PRIMARY__BG__COLOR);
  border: 1px solid var(--PRIMARY__BORDER__COLOR);
  color: var(--PRIMARY__CONTENT__COLOR);
  border-radius: 2px;
}
.verification-army-id {
  display: none;
}
.verification-card-drive {
  display: none;
}

.order-list,
.sell-list,
.assets-list {
  overflow: auto;
  height: 100%;
  // height: 134px;
  table {
    font-size: 0.75rem;
    width: 100%;
    border-spacing: 0;
    thead,
    tfoot {
      tr {
        background-color: var(--TABLE__HEADER__BG);
        th {
          text-align: center;
          padding: 0px 4px;
          white-space: nowrap;
        }
      }
    }
    tbody {
      tr {
        td {
          color: var(--TEXT__1);
          padding: 0px 4px;
        }
      }
      tr:nth-child(even) {
        background-color: var(--TABLE__EVEN__BG);
      }
      tr:nth-child(odd) {
        background-color: var(--TABLE__ODD__BG);
      }
      tr:hover {
        background-color: var(--TABLE__HOVER__BG);
      }
    }
    td,
    th {
      border: 1px solid var(--TABLE__BORDER__COLOR);
    }
    tr {
      height: 24px;
    }
  }
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #cef;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.when-hover-wrapper {
  .display-when-hover {
    display: none;
  }
  .hide-when-hover {
    display: block;
  }
  &:hover {
    .display-when-hover {
      display: block;
    }
    .hide-when-hover {
      display: none;
    }
  }
}

.input-order {
  background-color: var(--BG__4);
  padding: 0.5rem;
  border: 1px solid var(--INPUT__BORDER);
  border-radius: 4px;
}

.modal-trading-view {
  color: var(--TEXT__1);
  padding: 0;
  top: 0.625rem !important;
  min-width: 998px;
  width: calc(100vw - 448px) !important;
  .ant-modal-content {
    border-radius: 0;
    background-color: var(--BG__1);
    border: 1px solid var(--TEXT__TITLE__1);
    border-radius: 0.5rem;

    .ant-modal-header {
      background-color: var(--BG__1);
      text-align: center;
      text-transform: uppercase;
      border: 0;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding: 0;
      .drag_title {
        color: var(--TEXT__1);
      }
    }

    .ant-modal-body {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
  }

  .close-icon-modal {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
  }
}
.view-loading-shinhan {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.loading-shinhan {
  display: none;
}
.loading-shinhan:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 90px;
  height: 90px;
  border-top: 2px solid #d19f55;
  border-right: 2px solid #d19f55;
  border-radius: 50%;
  content: "";
  margin: -45px 0 0 -45px;
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
  -moz-animation: rotate 1s linear infinite;
  -o-animation: rotate 1s linear infinite;
}

.modal-loading-shinhan {
  .ant-modal-content {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    background-color: transparent;
  }
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

td.ant-table-cell {
  border: 1px solid var(--INPUT__BORDER) !important;
  white-space: nowrap;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-left: none !important;
}

th.ant-table-cell {
  background-color: var(--TABLE__HEADER__BG) !important;
  top: 0;
  border: 1px solid var(--INPUT__BORDER) !important;
  white-space: nowrap !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-left: none !important;
}

td.ant-table-cell:first-child {
  border: 1px solid var(--INPUT__BORDER) !important;
}

th.ant-table-cell:first-child {
  border: 1px solid var(--INPUT__BORDER) !important;
}

.ant-table-tbody > tr:nth-child(even).ant-table-row-level-0:hover > td {
  // background: unset !important;
  background-color: var(--TABLE__EVEN__BG) !important;
}

.ant-table-tbody > tr:nth-child(odd).ant-table-row-level-0:hover > td {
  // background: unset !important;
  background-color: var(--BG__2);
}

tr:nth-child(even):hover {
  background-color: var(--TABLE__EVEN__BG) !important;
}
tr:nth-child(odd):hover {
  background-color: var(--BG__2) !important;
}

.login_text {
  font-weight: bolder;
  text-shadow: #f5eded 0.1px 0.1px 0.1px;
}

.take_profit_stop_loss {
  .ant-card-body {
    padding: unset;
  }
  table {
    thead {
      tr {
        color: var(--TEXT__1)
      }
    }
  }
}

.ant-input-affix-wrapper{
  background: var(--BG__1) !important;
  border: 1px solid var(--BORDER__COLOR);
  &.text-right {
    .ant-input{
      text-align: right;
    }
  }
  .ant-input {
    background: var(--BG__1);
  }
}

.ant-table-tbody > tr > td > .ant-table-wrapper.oauthen-expanded-table:only-child .ant-table {
  margin: 0px;
}

.oauthen-table th.ant-table-cell {
  text-align: center !important;
  font-weight: bold;
}

.ant-table-row-expand-icon, .ant-table-row-expand-icon:hover {
  color: var(--PRIMARY) !important;
}

.ant-radio-wrapper {
  color: var(--PRIMARY__CONTENT__COLOR);
}

.bg_bound_login {
  background-color:#FFEED9; 
  padding: 60px 60px;
  background-position: center;
  background-repeat : no-repeat;
  background-size: 97% 97%;
  margin-inline: -40px
}