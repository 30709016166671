.bond-info-detail {
    .ant-modal-header {
        background: var(--HEADER__BG__COLOR);
        border-bottom: unset;
        border-radius: 14px 14px 0 0;
    }
    .ant-modal-title {
        text-align: center;
        color: var(--TEXT__TITLE__1);
    }
}

.ant-modal-root .bond-info-detail .ant-modal-close {
    display: block;
}