.modal-ekyc {
    #ekyc_sdk_intergrated {
        background: var(--HEADER__BG__COLOR);
    }
    .ant-modal-body {
        padding: 12px;
    }
    .common-btn {
        background: var(--PRIMARY);
    }
    .capture-and-upload-block .capture-and-upload-wrapper .btn-upload-image {
        border: 2px solid var(--PRIMARY);
    }
    .capture-and-upload-block .capture-and-upload-wrapper .btn-upload-image span {
        color: var(--PRIMARY);
    }
    #animation svg g path {
        fill: var(--HEADER__BG__COLOR);
    }
}
.modal-ekyc-old {
    .ant-modal-content {
        background-color: #122f41;
    }
    .ant-modal-body {
        padding: 12px;
    }
    .common-btn {
        background: var(--PRIMARY);
    }
    .capture-and-upload-block .capture-and-upload-wrapper .btn-upload-image {
        border: 2px solid var(--PRIMARY);
    }
    .capture-and-upload-block .capture-and-upload-wrapper .btn-upload-image span {
        color: var(--PRIMARY);
    }
    #animation svg g path {
        fill: #122f41;
    }
}
.modal-ekyc-final {
    .ant-modal-body {
        padding: 12px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 26px;
    }
    .ant-picker.ant-picker-middle {
        width: 50%;
    }
    .ant-select {
        width: 100%;
    }
    .bank-select-ekyc {
        width: 71%;
        .ant-select-selection-item {
            white-space: normal;
        }
    }
    .circle-style {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #00b0ff;
        margin-right: 5px;
    }
}

.row-ekyc {
    display: flex;
    flex-direction: row;
    padding-top: 4px;
    padding-bottom: 4px;
    .ant-picker, .ant-select-selector {
        border: none!important;
    }
    input {
        padding-right: 11px;
        padding-left: 11px;
    }
    .ant-picker-input input {
        padding-right: 0;
        padding-left: 0;
    }

    .ant-picker-input > input,.ant-select {
        font-size: 0.75rem;
    }

    .ant-picker.ant-picker-disabled{
        opacity: .5;
        border-color: var(--BORDER__COLOR);
        background-color: var(--INPUT__DISABLED);
        color: var(--TEXT__1);
        .ant-picker-input > input[disabled] {
            color: var(--TEXT__1) !important;
        }
    }
}

.preview-sign {
    height: 400;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.ant-select-dropdown.list-bank-cd {
    width: 250px!important;
}