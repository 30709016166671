.statement__exchange{
    .lable-statement-exchange{
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: bold;
        color: var(--TEXT__1);
    }
}


.table_statement_exchange {
    position: relative;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    &.sticky {
        thead {
            background-color: var(--TABLE__HEADER__BG);
            position: sticky;
            top: -1px;
        }
    }
    tbody tr td > span {
        padding: 1px 2px 1px 1px;
    }
    thead {
        background-color: var(--TABLE__HEADER__BG);
        // z-index: 99;
    }
    thead tr th{
        border: 1px solid var(--INPUT__BORDER);
        box-sizing: border-box;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: center;
        white-space: nowrap;
        // height: 30px;
    }
    tbody tr td {
        box-sizing: border-box;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 30px;
        white-space: nowrap;
    }
    tbody tr td, tfoot tr td {
        border: 1px solid var(--TABLE__BORDER__COLOR);
    }
    tfoot tr td {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}