.overview__layouts {
    display: flex;

    .index__overview__group {
        flex: 1;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .footer__chart {
        padding: 0 2rem;
        .ant-select {
            color: var(--TEXT__1);
            .ant-select-selector {
                background-color: transparent;
                border: none;
            }
            .ant-select-arrow {
                color: var(--TEXT__1);
            }
        }
        .ant-select-selector span {
            font-size: 0.9rem;
        }
        span {
            font-size: 0.9rem;
            display: flex;
        }
    }
    .highcharts-index {
        position: relative;
        z-index: 0;
    }
    .highcharts-index__bg__overview {
        position: absolute;
        width: calc(100% - 48px);
        height: calc(100% - 44px);
        min-width: calc(360px - 48px);
        border-radius: 4px;
        // background-color: var(--TABLE__HEADER__BG);
        bottom: 36px;
        left: 8px;
        z-index: -1;
        // border: 1px solid var(--INPUT__BORDER);
    }
    .highcharts-plot-background {
        fill: var(--TABLE__HEADER__BG);
        // border: 1px solid var(--INPUT__BORDER);
        // border-radius: 8px;
    }
}
