/**
* Dùng style overview priceboard bao gồm chart, ...
*
*
*/

.overview__priceBoard {
    display: flex;
    overflow: auto;
    height: 100%;
    flex-direction: row;
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
    .highcharts-container {
        transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
    }
}
.footer__chart {
    transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
    .ant-select-selector span {
        font-size: 0.8rem;
    }
    span {
        font-size: 0.7rem;
        display: flex;
    }
    .ant-select {
        color: var(--TEXT__1);
        width: auto;
        display: flex;
        align-items: center;
        max-width: 85px;
        overflow: hidden;
        height: 18px;
        .ant-select-selector {
            background-color: transparent;
            border: none;
            font-size: 0.75rem;
            height: auto;
            padding: unset;
            flex: 1;
            color: #ce9b51;
        }
        .ant-select-arrow {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            position: unset;
            color: var(--TEXT__1);
            width: unset;
            height: none;
            top: unset;
            padding-left: 0px;
            padding-top: 6px;
            margin-left: -28px;
            line-height: 1;
        }
    }

    .row_index_info {
        align-items: center;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        // margin-right: 32px;
        margin-right: 18px;
    }
    .footer__chart__select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .ant-table {
        line-height: 1.5;
    }
}

body {
    .chart-group {
        display: flex;
        height: fit-content;
        transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
        .highcharts-index > div {
            transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
        }
        .highcharts-index {
            flex: 4;
            margin: 4px 0 0px 0;
            position: relative;
            z-index: 0;
            transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
            .highcharts-index__bg {
                position: absolute;
                width: calc(16.3vw - 16px);
                height: calc(100% - 32px);
                min-width: calc(240px - 16px);
                border-radius: 4px;
                background-color: var(--BG__2);
                bottom: 28px;
                z-index: -1;
                border: 1px solid var(--INPUT__BORDER);
                transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
            }
        }

        .single__chart_block {
            transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
            width: calc(16.3vw);
            min-width: 235px;

            .overview_pb_openchart_icon {
                visibility: hidden;
                top: 0.5rem;
                right: calc(0.5rem);
            }
            &:hover {
                .overview_pb_openchart_icon {
                    visibility: visible;
                }
            }
        }
    }
    .single__chart_block {
        padding: 0px 8px 0px 8px;
        transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
        position: relative;
        .overview_pb_openchart_icon {
            visibility: hidden;
            right: 4rem;
            top: 1rem;
        }
        &:hover {
            .overview_pb_openchart_icon {
                visibility: visible;
            }
        }
    }

    .index-overview {
        width: 36vw;
        min-width: fit-content;
        height: fit-content;
        transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
        span {
            font-size: 0.75rem;
        }
        th.ant-table-cell {
            font-size: 0.75rem;
        }
        .index-overview-table {
            background-color: var(--BG__2);
            padding: 0rem 0.5rem 0.25rem 0.5rem;
            border-radius: 8px;
            margin: 8px;
            font-size: 0.8rem;
            // border: 1px solid var(--INPUT__BORDER);
            transition: height 0.3s cubic-bezier(0.4, 0, 1, 1);
        }
    }
}

.overview_pb_openchart_icon {
    position: absolute;
    z-index: 100;
    background-color: var(--BG__2);
    backdrop-filter: blur(50px);
}
