/**
* Overide antd (Hạn chế dùng)
*
*
*/
.ant-tooltip-inner {
    color: var(--PRIMARY__CONTENT__COLOR);
    background-color: var(--INPUT__BG);
    // box-shadow: var(--INPUT__BORDER) 1px 1px 4px;
    border: 1px solid var(--INPUT__BORDER);
    border-radius: 8px;
}
.ant-tooltip-arrow-content {
    background-color: var(--INPUT__BG);
    border: 1px solid var(--INPUT__BORDER);
    width: 8px;
    height: 8px;
}
// .feedback {
.ant-tabs-nav-list {
    width: 100% !important;
}
// }
.cpn-stock-info {
    .ant-btn {
        border-radius: 6px;
        font-size: 18px;
        padding: 0px 20px;
    }
    .electrical_panel_stock-info {
        justify-content: center;
        td,
        th {
            border: 1px solid #dddddd;
            text-align: center;
        }
    }
    .stock-info-news-event,
    .see-more {
        .ant-tabs {
            color: black;
        }
        .ant-tabs-tab:hover {
            color: black !important;
        }
        .ant-tabs-tab {
            margin-right: 0px !important;
            border-radius: 0 0 0 0;
            padding: 3px 18px !important;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: brown;
        }
    }
    .stock-info-industry {
        justify-content: center;
        td,
        th {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .tab-fundamental-right {
        .tab-fundamental {
            tr {
                border-bottom: 1px solid gray;
            }
            td {
                padding-left: 10px;
                height: 30px;
            }
        }
    }
}

// WEBV3_TONG
.ant-dropdown {
    min-width: 228px !important;
}

.ant-col-custom.ant-col {
    display: flex;
    flex: 1;
    align-self: auto;
}

.ant-modal-root .ant-modal-close {
    display: none;
}

.ant-divider-horizontal.ant-divider-with-text:after,
.ant-divider-horizontal.ant-divider-with-text:before {
    border-top-color: #dddddd;
}

.ant-modal-content {
    background-color: var(--HEADER__BG__COLOR);
    border-radius: 12px;
    border: 1px solid #ce9b51;
}

.ant-form-item-label > label {
    color: var(--TEXT__1);
}

.ant-table {
    background-color: var(--TABLE__EVEN__BG);
    color: var(--TEXT__1);

    .ant-table-thead > tr > th {
        background: var(--TABLE__HEADER__BG);
        color: var(--TEXT__1);
        border: 1px solid var(--DIVIDER__COLOR);
    }
    .ant-table-tbody > tr > td {
        color: var(--TEXT__1);
        border: 1px solid var(--DIVIDER__COLOR);
    }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    padding: 0px 4px;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: var(--TABLE__HOVER__BG);
}
.ant-drawer-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 0.8rem;
}
.ant-select {
    color: var(--PRIMARY__CONTENT__COLOR);
}
.ant-radio-button-wrapper {
    color: var(--PRIMARY__CONTENT__COLOR);
    background-color: var(--PRIMARY__BG__COLOR);
    border-color: var(--PRIMARY__BORDER__COLOR);
    font-size: 0.8rem;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
    color: var(--SECOND__CONTENT__COLOR);
}
.ant-btn {
    border-width: 0px;
    // background-color: inherit;
}

.ant-input {
    background-color: var(--PRIMARY__BG__COLOR);
    color: var(--PRIMARY__CONTENT__COLOR);
    border-color: var(--PRIMARY__BORDER__COLOR);
    font-size: 0.75rem;
    // padding: 4px;
}
.ant-input.ant-input-sm {
    height: 24px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--PRIMARY__BORDER__COLOR);
}
.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--PRIMARY__BG__COLOR);
    color: var(--PRIMARY__CONTENT__COLOR);
    border-color: var(--PRIMARY__BORDER__COLOR);
}

.ant-select-arrow {
    color: var(--PRIMARY__CONTENT__COLOR);
}
.ant-radio-button-wrapper:first-child {
    border-color: var(--PRIMARY__BORDER__COLOR);
}

.ant-input[disabled] {
    background-color: var(--INPUT__DISABLED);
    color: var(--TEXT__1);
    opacity: 0.5;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input:hover {
    background-color: var(--PRIMARY__BG__COLOR);
}

.ant-descriptions {
    .ant-descriptions-item-label {
        color: var(--TEXT__2);
        background-color: var(--TABLE__HEADER__BG);
        font-size: 0.75rem;
    }
    .ant-descriptions-item-content {
        color: var(--TEXT__1);
        min-width: 100px;
        font-size: 0.75rem;
    }
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: 1px solid var(--DIVIDER__COLOR);
}
.ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid var(--DIVIDER__COLOR);
}

.ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid var(--DIVIDER__COLOR);
}
.ant-layout-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}
.ant-tabs {
    color: var(--TEXT__1);
    font-size: 0.75rem;
}
.ant-select-item-option-content {
    font-size: 0.75rem;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
}

// .ant-table .ant-table-tbody > tr > td {
//   background: var(--BG__1);
// }
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: var(--BG__1);
}
.ant-tabs-tab-btn {
    color: var(--TEXT__1);
    font-size: var(--normal);
}
.ant-card {
    background: var(--BG__1);
}
.ant-picker-input > input {
    background-color: var(--PRIMARY__BG__COLOR);
    color: var(--PRIMARY__CONTENT__COLOR);
    border-color: var(--PRIMARY__BORDER__COLOR);
}
// .ant-btn:hover{
//   background-color: var(--PRIMARY__BG__COLOR)
// }
.ant-modal {
    color: var(--TEXT__1);
}

.ant-message .anticon,
.ant-message-notice {
    font-size: 20px;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
    border-color: var(--DIVIDER__COLOR);
}
.ant-btn-background-ghost {
    border-color: transparent !important;
}
.ant-checkbox-inner {
    background-color: var(--PRIMARY__BG__COLOR);
    border-color: var(--BORDER__CHECKBOX);
}
.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: var(--BORDER__CHECKBOX);
    border-color: var(--BORDER__CHECKBOX) !important;
}

.ant-checkbox-wrapper {
    color: var(--PRIMARY__CONTENT__COLOR);
    border-color: var(--BORDER__CHECKBOX);
    font-size: 0.75rem;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: var(--PRIMARY__BG__COLOR);
    border-color: var(--BORDER__CHECKBOX);
}

.ant-message-notice-content {
    padding: 0;
}
.ant-message-custom-content.ant-message-warning {
    padding: 10px 16px;
    background-color: var(--WARN__COLOR);
}
.ant-message-custom-content.ant-message-success {
    padding: 10px 16px;
}
.ant-message-custom-content.ant-message-info {
    padding: 10px 16px;
}

.ant-divider-horizontal {
    margin: 12px 0;
    border-color: var(--BORDER__COLOR);
}

.ant-btn-primary {
    background-color: var(--PRIMARY);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: var(--PRIMARY);
    border-color: var(--PRIMARY);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: var(--TABS_TAB);
    border-color: var(--BORDER__COLOR);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: var(--PRIMARY);
    border-color: var(--PRIMARY);
}
.ant-divider {
    border-color: var(--BORDER__COLOR);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 0;
}

.ant-menu.ant-menu-horizontal li.ant-menu-item {
    padding: 0 4px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 4px solid transparent;
}
.ant-menu-horizontal {
    line-height: 3;
}

li.ant-menu-item.ant-menu-item-only-child {
    font-size: 0.75rem;
}
.ant-menu-submenu {
    li.ant-menu-item.ant-menu-item-only-child {
        height: 2rem;
        line-height: 2rem;
    }
}

.DARK {
    .ant-dropdown-menu {
        background-color: #1f1f1f;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: #ffffff14;
    }
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: var(--TEXT__1);
    }
    .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
        // -webkit-box-shadow: 0 -6px 16px -8px rgba(0,0,0,32%), 0 -9px 28px 0 rgba(0,0,0,20%), 0 -12px 48px 16px rgba(0,0,0,12%);
        // box-shadow: 0 -6px 16px -8px rgba(0,0,0,32%), 0 -9px 28px 0 rgba(0,0,0,20%), 0 -12px 48px 16px rgba(0,0,0,12%);
        box-shadow: 0 -6px 16px -8px #ffffff14, 0 -9px 28px 0 #ffffff0d, 0 -12px 48px 16px #ffffff08;
        -webkit-box-shadow: 0 -6px 16px -8px #ffffff14, 0 -9px 28px 0 #ffffff0d, 0 -12px 48px 16px #ffffff08;
    }
}

.ant-picker-panel-container {
    border-radius: 0.5rem;
    background-color: var(--BG__2);
    border: 1px solid var(--TEXT__TITLE__1);
    // width: 17rem; // set width lỗi range picker
    // border: 1px solid var(--TEXT__TITLE);
    .ant-picker-panel {
        border: 0;
    }
    .ant-picker-header-view {
        font-size: 1.25rem;
        color: var(--TEXT__TITLE__1);
    }

    .ant-picker-content {
        th {
            // color: var(--PRIMARY);
            color: var(--TEXT__1);
        }
    }
    .ant-picker-header,
    .ant-picker-footer {
        border: 0;
    }
    .ant-picker-cell {
        color: var(--TEXT__TITLE__1);
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner {
        background-color: var(--BORDER__COLOR);
    }
}
.tab-financial-statement {
    // height: calc(100% - 150px);
    overflow: auto;
    .ant-tabs-tab {
        // margin: 0;
        // border: 0;
        // border-radius: 0.25rem 0.25rem 0 0 !important;
        background: var(--PRIMARY__BG__COLOR);
        // color: var(--COLOR__TEXT__TAB);
        // padding: 8px 8px;
        // width: fit-content;
        // display: inline !important;
    }
    .ant-tabs-tab-active {
        background: var(--PRIMARY__BG__COLOR) !important;
        // background-color: var(--PRIMARY) !important;
    }
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: 2px solid var(--PRIMARY__BG__COLOR);
        z-index: 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--TEXT__1);
    }
    .ant-tabs-nav-list {
        justify-content: center;
    }
}
.ant-tabs-tab {
    margin: 0;
    border: 0;
    border-radius: 0.25rem 0.25rem 0 0 !important;
    background: var(--INPUT__BG);
    color: var(--COLOR__TEXT__TAB);
    padding: 8px 8px;
    width: fit-content;
    display: inline !important;
    .anticon {
        margin-right: unset;
    }
}
// .ant-tabs-tabpane {
//     padding: 10px;
//     height: 100%;
// }
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 2px solid var(--PRIMARY);
    z-index: 99;
}
.ant-tabs-tab-active {
    background-color: var(--PRIMARY) !important;
}
::-webkit-scrollbar-thumb {
    background-color: var(--PRIMARY);
}

.ant-modal {
    width: 400px;
}
.ant-modal-body {
    font-size: 0.75rem;
    max-height: calc(100vh - 110px);
    overflow: auto;
}
.ant-btn {
    font-size: 0.875rem;
    justify-content: center;
    align-items: center;
    display: flex;
    // padding: 0 15px;
}
.ant-btn-lg {
    padding: 0 15px;
}

.ant-modal-mask {
    background-color: transparent;
}

// Công
.ant-notification-notice {
    padding: 14px 18px;
    border-radius: 5px;
}
.ant-notification-notice-description {
    font-size: 0.75rem !important;
    font-weight: normal;
    font-style: normal;
    line-height: 16px;
    color: #9ca4af;
}

.ant-empty-description {
    color: var(--PRIMARY__CONTENT__COLOR);
}

.ant-tabs-tab {
    border: 0 !important;
    margin-right: 0.2rem !important;
}

.ant-picker-cell-disabled::before {
    background: transparent;
}

.tab-order-list-asset .ant-tabs-tab .anticon {
    margin-right: unset;
}

.ant-picker-cell-disabled .ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: var(--DATE_DISABLED);
}
.ant-picker-range {
    background: var(--PRIMARY__BG__COLOR);
    border-color: var(--BORDER__COLOR);
}
.ant-picker-range-arrow::after {
    border-color: var(--BG__2);
}
.DARK {
    .ant-picker-header button,
    .ant-picker-separator,
    .ant-picker-suffix,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
        color: var(--TEXT__TITLE__1);
    }
    .ant-drawer .ant-picker-clear {
        color: #ffffff4d;
        background: #141414;
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: #263c54;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background: #263c54;
    }
    .ant-table-cell-scrollbar {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border-bottom: 4px solid var(--PRIMARY);
    color: var(--PRIMARY);
    font-weight: 500;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: var(--TABLE__HEADER__BG);
}
.ant-table-body {
    overflow: auto auto !important;
}
.ant-table {
    tr:nth-child(odd) {
        td {
            background-color: var(--BG__2);
        }
    }

    tr:nth-child(even) {
        td {
            background-color: var(--BG__1);
        }
    }
}

// Tông
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border-bottom: 0.125rem solid var(--PRIMARY);
}

label.ant-form-item-required {
    font-size: 0.75rem;
}

.ant-form-item-explain.ant-form-item-explain-error > div {
    font-size: 0.75rem;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding: 0;
}

li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    font-weight: bold;
}

li.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected span {
    font-weight: bold;
    color: #fff;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    color: var(--TEXT__1);
    font-size: 0.75rem;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
}
.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
    background: var(--DATE_DISABLED);
}

.ant-input:focus,
.ant-input-focused {
    border-color: #40a9ff !important;
    // border-right-width: 1px !important;
    // outline: 0;
    /* -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%); */
    /* box-shadow: 0 0 0 2px rgb(24 144 255 / 20%); */
}

.right-up-down {
    .ant-menu-item {
        padding: 0 0.7rem !important;
    }
}

.tooltip-override {
    // top: 160px !important;
}

.ant-menu-item-active a div {
    color: #f5f5f5 !important;
}

.ant-menu-item-selected a div {
    background: var(--PRIMARY);
}

.ant-input-group-addon {
    padding: 0 0.25rem;
    font-size: 0.75rem;
    line-height: 1.2;
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
    color: #000000;
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
    color: #000000;
}

.ant-radio-wrapper-disabled span {
    color: var(--TEXT__1);
}

.ant-select-disabled .ant-select-selector {
    color: var(--TEXT__1) !important;
    background: var(--BG__1) !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

.ant-radio-disabled .ant-radio-inner::after {
    background-color: var(--PRIMARY) !important;
    opacity: 0.65 !important;
}

.ant-picker {
    background: var(--PRIMARY__BG__COLOR);
    border-color: var(--BORDER__COLOR);
}

.ant-result-title {
    color: var(--PRIMARY__CONTENT__COLOR);
}

.ant-result-subtitle {
    color: var(--PRIMARY__CONTENT__COLOR);
}
.ant-result-extra {
    display: flex;
    justify-content: center;
}

.price-board .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border-bottom: 0.25rem solid var(--PRIMARY) !important;
}

.ant-tabs-tab + .ant-tabs-tab {
    margin: unset;
}

li.price-board .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 0;
}

.assets-list table thead tr th.text-right {
    text-align: right;
}

.ant-picker.ant-picker-disabled{
    opacity: .5;
    background: var(--PRIMARY__BG__COLOR);
    border-color: var(--BORDER__COLOR);
}

.ant-list-bordered {
    border: 1px solid var(--BORDER__CHECKBOX);
}
.ant-list-item {
    color: var(--PRIMARY__CONTENT__COLOR);
}
.ant-list-split .ant-list-item {
    border-bottom: 1px solid var(--BORDER__CHECKBOX);
}

.ant-upload.ant-upload-select-picture-card{
    border-radius: 20%;
}

.special-row{
    width: 23rem;
}

.special-row .mr-2.alt-label.flex-2 {
    margin-right: 4.5rem !important;
}

.special-row .mr-1.alt-label.flex-2 {
    margin-right: 4.5rem !important;
}

.mask-hover-layout{
    position: absolute;
    top: 0;
    left: 18%;
    background: silver;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.65;
}

.icon-edit{
    svg{
        width: 2rem !important;
        height: 2rem !important;
    }
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: var(--PRIMARY);
}
.ant-radio-button-wrapper:hover {
    position: relative;
    color: var(--PRIMARY);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: var(--PRIMARY);
    border-color: var(--PRIMARY);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: var(--PRIMARY);
}
.ant-input-suffix {
    font-size: 0.625rem;
    color: var(--SECOND__CONTENT__COLOR);
}
.upload-sign {
    .ant-upload-picture-card-wrapper {
        width: auto;
    }
    .ant-upload.ant-upload-select-picture-card {
        width: 160px;
        height: 160px;
        background-color: inherit;
        border-color: var(--BORDER__CHECKBOX);
    }
}

.ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner {
    color: var(--PRIMARY__BORDER__COLOR);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    background: var(--PRIMARY);
    border-color: var(--PRIMARY);
    opacity: 0.85;
}
.ant-input-affix-wrapper-disabled {
    background-color: var(--BG__1);
    opacity: 0.5;
}
.priceboard-menu-watchlist .ant-menu-vertical.ant-menu-sub {
    max-height: 30vh;
    overflow: auto;
}

.otp-options > label {
    margin-right: 1rem;
}
.otp-options .ant-radio-wrapper {
    color: var(--TEXT__1);
}
.otp-options .ant-radio-wrapper-disabled span {
    color: var(--DATE_DISABLED);
}

.ant-switch-checked {
    background-color: var(--PRIMARY);
}

.ant-btn-ghost {
    color: var(--SECOND__CONTENT__COLOR);
}