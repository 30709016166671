

.router_select {
    // margin-top: 1rem;
    margin-bottom: 0.5rem;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
    }
    .ant-select-selection-item {
        color: #CE9B51;
        font-size: 1.2rem;
        font-weight: 700;
    }
    .ant-select-arrow {
        // background-color: ivory;
        top: 35%;
    }
    .ant-select-selector {
        background-color: transparent !important;
        padding-left: 0 !important;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        cursor:unset;
    }
}