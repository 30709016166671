.view-finance {
    padding: 8px 16px;
    background-color: var(--TABLE__HEADER__BG);
    border-radius: 8px;
    margin: 4px 0;
    display: flex;
    flex-direction: column;
}

.see__more__table {
    .title-left{
        text-align: left !important ;
    }
    .text-color-info{
        color: var(--INFO__COLOR) !important; 
    }
    th {
        border: 1px solid var(--INPUT__BORDER);
        text-align: initial ;
        color: var(--INFO__COLOR);
        font-weight: normal;
        white-space: nowrap
    }
    td {
        height: 25px;
        border: 1px solid var(--INPUT__BORDER);
        // white-space: nowrap;
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;
        // color: var(--TEXT__1);
    }
    tr:nth-child(odd) {
        background-color: var(--TABLE__ODD__BG);
    }
    tr:nth-child(even) {
        background-color: var(--TABLE__EVEN__BG);
    }
    tr:nth-child(1) {
        background-color: var(--TABLE__HEADER__BG);
        font-weight: bold;
    }
    
}


.view {
    // margin: auto;
    // width: 600px;
    .wrapper {
        // position: relative;
        overflow: auto;
        border: 1px solid var(--INPUT__BORDER);
        white-space: nowrap;
        .sticky-col {
            position: -webkit-sticky;
            position: sticky;
            // background-color: white;
            // border: 1px solid red;
          }
          .first-col {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            left: 0px;
            z-index: 99;
            // border: 1px solid red;
            // background-color: var(--TABLE__HEADER__BG);
          }
        th{
            position: sticky;
            top: 0;
            border: 1px solid var(--INPUT__BORDER);
            z-index: 99;
            background-color: var(--TABLE__HEADER__BG);
            color: VAR(--TEXT__TITLE__1) !important;
            height: 26px;
        }
        tr:nth-child(odd) {
            background-color: var(--BG__1);
           // z-index: 99999;
            
        }
        tr:nth-child(even) {
            background-color: var(--BG__2);
            //z-index: 99999;
        }
        td{
            height: 27px;
            border: 1px solid var(--INPUT__BORDER);
        }
    }
}
.stock-info {
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        padding: 8px;
    }
}
  