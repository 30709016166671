.quick-order {
    display: flex;
    position: relative;
    border-top: solid 1px var(--TABLE__BORDER__COLOR);
}

.switch-order {
    .ant-switch {
        background-color: var(--SELL__BG);
        .ant-switch-handle::before {
            background-color: var(--BUY__BG);
            content: attr(type);
            padding-top: 7px;
            color: #fff;
            border-radius: 30px;
        }
    }
    .ant-switch-checked {
        background-color: var(--BUY__BG);
        .ant-switch-handle::before {
            background-color: var(--SELL__BG);
            content: attr(type);
            padding-top: 7px;
            color: #fff;
            border-radius: 30px;
        }
    }
    .ant-switch {
        min-width: 140px;
        height: 38px;
    }
    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 49px);
    }
    .ant-switch-handle {
        width: 49px;
        height: 38px;
        top: 0px;
        left: 0px;
    }
    .ant-switch-inner {
        font-size: 16px;
    }
}

.menu_bar_switch_lang {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .ant-switch {
        min-width: 85px;
        height: 22px;
        background-color: var(--PRIMARY);
    }
    .ant-switch-handle {
        width: 22px;
        height: 22px;
        top: 0;
        left: 0;
    }
    .ant-switch .ant-switch-handle:before {
        background-color: #fff;
        content: attr(type);
        padding-top: 7px;
        border-radius: 30px;
    }
    .switch-order .ant-switch-inner {
        font-size: 16px;
    }
    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 22px);
    }
    .menu_bar_switch_lang .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 22px);
    }
    .ant-switch-checked {
        background-color: var(--DOWN__COLOR);
    }
}

.search-stock.ant-select-auto-complete {
    min-width: 200px;
    input {
        text-transform: uppercase;
    }
}
.search-stock .ant-select-item-option-content {
    font-family: monospace;
}
.ant-select-dropdown.search-stock {
    min-width: 250px !important;
}
.quick-order-modal,
.normal-order {
    .ant-drawer-wrapper-body {
        border-top: 1px solid var(--TEXT__TITLE__1);
        width: 100vw;
    }
    .ant-drawer-content {
        background-color: var(--PRIMARY__BG__COLOR);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: var(--BG__4);
        border-color: var(--INPUT__BORDER);
    }
    .ant-picker  {
        padding: 4px;
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            padding: 0 4px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            padding-right: 24px;
        }
        .ant-picker-range-separator{
            padding: 0 4px 0 0;
        }
        .ant-picker-suffix {
            margin-left: 0px;
        }
    }
    .ant-input {
        background-color: var(--BG__4);
        border-color: var(--INPUT__BORDER);
    }
    .ant-tabs-tab {
        padding-top: 0;
        padding-bottom: 4px;
    }
    .ant-tabs-tab-btn {
        font-size: 0.8rem;
        color: var(--TEXT__1);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff;
    }
    .ant-drawer-content-wrapper {
        min-width: 1280px;
    }
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
        margin-bottom: 4px;
    }
    .ant-tabs-content.ant-tabs-content-top {
        height: 100%;
    }
    .tab-order-list-asset {
        .ant-tabs-content.ant-tabs-content-top {
            // height: 205px;
        }
    }
    .ant-descriptions-bordered .ant-descriptions-item-label,
    .ant-descriptions-bordered .ant-descriptions-item-content {
        padding: 4px;
    }
    // .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    //   border-bottom-color: transparent;
    // }
    .ant-tabs-tabpane {
        padding: 0;
    }
}
.normal-order {
    .tab-order-list-asset {
        .ant-tabs-content.ant-tabs-content-top {
            height: 253px;
        }
    }
}

.row-modal {
    padding: 3px 0px;
    align-items: center;
}

.normal-order {
    padding: 8px;
}

.ant-btn.btn-cancel {
    background-color: transparent;
    color: var(--TEXT__1);
    border: 0.5px solid var(--BORDER__COLOR);
}
.ant-btn.btn-cancel:hover {
    border-color: var(--PRIMARY);
}
.border-center-order {
    width: 1px;
    background-color: var(--TEXT__TITLE__1);
    height: calc(100% + 24px);
    margin-left: 5px;
    margin-top: -12px;
}
.border-vertical-order {
    margin-top: 5px;
    height: 1px;
    background-color: var(--TEXT__TITLE__1);
}
.total-orderlist {
    position: absolute;
    top: 22px;
    right: 75px;
}
.normal-order .total-orderlist {
    position: absolute;
    top: 10px;
    right: 50px;
}
@media only screen and (max-width: 1270px) {
    .total-orderlist {
        display: none;
    }
}

.load-wrapp {
    float: left;
    width: 84px;
}

.serverTime {
    font-size: 15px;
    font-family: monospace;
}

.load-wrapp p {
    padding: 0 0 20px;
}
.load-wrapp:last-child {
    margin-right: 0;
}

.letter {
    float: left;
    color: red;
    font-weight: 600;
}
.load-animation .letter {
    animation-name: loadingF;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}
@keyframes loadingF {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.l-1 {
    animation-delay: 0.48s;
}
.l-2 {
    animation-delay: 0.6s;
}
.l-3 {
    animation-delay: 0.72s;
}
.l-4 {
    animation-delay: 0.84s;
}
.l-5 {
    animation-delay: 0.96s;
}
.l-6 {
    animation-delay: 1.08s;
}
.l-7 {
    animation-delay: 1.2s;
}
.l-8 {
    animation-delay: 1.32s;
}
.l-9 {
    animation-delay: 1.44s;
}
.l-10 {
    animation-delay: 1.56s;
}
.l-11 {
    animation-delay: 1.68s;
}
.l-12 {
    animation-delay: 1.8s;
}

@-webkit-keyframes warning {
    0% {
        fill: yellow;
    }
    30% {
        fill: yellow;
    }
    90% {
        fill: '#EF9A9A';
    }
    100% {
        fill: green;
    }
}
@-moz-keyframes warning {
    0% {
        fill: yellow;
    }
    30% {
        fill: yellow;
    }
    90% {
        fill: '#EF9A9A';
    }
    100% {
        fill: green;
    }
}
@-o-keyframes warning {
    0% {
        fill: yellow;
    }
    30% {
        fill: yellow;
    }
    90% {
        fill: '#EF9A9A';
    }
    100% {
        fill: green;
    }
}
@keyframes warning {
    0% {
        fill: yellow;
    }
    30% {
        fill: yellow;
    }
    90% {
        fill: '#EF9A9A';
    }
    100% {
        fill: green;
    }
}
.wifiWarning path {
    -webkit-animation: warning 2000ms infinite;
    -moz-animation: warning 2000ms infinite;
    -o-animation: warning 2000ms infinite;
    animation: warning 2000ms infinite;
    fill: var(--REF__COLOR);
}

.signal {
    margin: auto 8px;
    font-size: 0.625rem;
    color: var(--UP__COLOR);
    svg {
        width: 0.625rem;
    }
    path {
        fill: var(--UP__COLOR);
    }
}

.quick-order-modal,
.normal-order {
    .ant-radio-button-wrapper {
        background-color: var(--TABLE__HEADER__BG);
    }
}

#input_price_quick_order,
#input_qty_quick_order,
#input_duplicate_order {
    padding-top: 5px;
    padding-bottom: 0;
    font-size: 0.875rem;
}

.row-label {
    width: 165px;
    margin: auto 0;
}
.row-value {
    height: 26px;
    width: 150px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    background-color: var(--BG__4);
    border: 1px solid var(--INPUT__BORDER);
    border-radius: 4px;
    padding-right: 4px;
}

.input-price {
    .ant-select-selection-search {
        left: 42px!important;
    }
}
.input-price-prefix {
    position: absolute;
    top: 7px;
    height: 25px;
    font-size: 0.875rem;
    left: 12px;
    z-index: 9;
}