.title-popup-confirm{
    font-size: 22px;
    font-weight: 700;
    text-align: center ;
    // color: var(--PRIMARY__BG__COLOR);
    color: var(--TEXT__1);
}

.odd_lot {
    .table-right-odd-lot {
        overflow:auto;
        min-height: auto;
        max-height: calc(100vh - 320px);
        border: 1px solid var(--INPUT__BORDER);
        th {
            height: 35px;
            position: sticky;
            z-index: 4;
            background-color: var(--TABLE__HEADER__BG);
            top: 0px;
            border-left: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        td {
            height: 30px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}