.margin__payback {
    .table-left-margin-payback {
        overflow:scroll;
        min-height: auto;
        max-height: 55vh;
        // min-width: auto;
        // width: 1000px;
        th {
            height: 35px;
            position: sticky;
            z-index: 4;
            background-color: var(--TABLE__HEADER__BG);
            top: -2px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        td {
            height: 30px;
            border: 1px solid var(--INPUT__BORDER);
            white-space: nowrap;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        // .ant-table {
        //     background-color: var(--TABLE__EVEN__BG);
        //     color: var(--TEXT__1);
        //     border-top: 1px solid var(--INPUT__BORDER) !important;
        //     border-left: 1px solid var(--INPUT__BORDER) !important;

        //     .ant-table-thead > tr > th {
        //         background: var(--TABLE__HEADER__BG);
        //         color: var(--TEXT__1);
        //         border: none;
        //         border-right: 1px solid var(--INPUT__BORDER) !important;
        //         height: 35px;
        //     }
        //     .ant-table-tbody > tr > td {
        //         color: var(--TEXT__1);
        //         border: none;
        //         border-bottom: 1px solid var(--INPUT__BORDER) !important;
        //         border-right: 1px solid var(--INPUT__BORDER) !important;
        //         height: 35px;
        //     }
        // }
    }
}
