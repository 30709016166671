.modal-alert.info {
  path:first-child {
    fill: var(--PRIMARY);
  }
}
.modal-alert.warn {
  path:first-child {
    fill: var(--WARN__COLOR);
  }
}
.modal-alert.error {
  path:first-child {
    fill: var(--ERROR__COLOR);
  }
}
.modal-alert.success {
  path:first-child {
    fill: var(--SUCCESS__COLOR);
  }
}
