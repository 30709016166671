.right__to__buy {
    .table-right-to-buy {
        overflow: auto;
        min-height: auto;
        max-height: calc(100vh - 200px);
        // min-width: auto;
        // width: 1000px;
        // min-height: calc(100vh - 500px);
        //   overflow-y: auto;
        border-top: 1px solid VAR(--INPUT__BORDER);
        border-right: 1px solid VAR(--INPUT__BORDER);
        border-left: 1px solid VAR(--INPUT__BORDER);
        // .table-right-to-buy-content {
            th {
                height: 35px;
                position: sticky;
                z-index: 4;
                background-color: var(--TABLE__HEADER__BG);
                top: 0px;
                border-left: 1px solid var(--INPUT__BORDER);
                white-space: nowrap;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
            td {
                height: 30px;
                border: 1px solid var(--INPUT__BORDER);
                white-space: nowrap;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        // }
    }
}
